import React,{useState,useEffect,useMemo} from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Pdf from "../Components/Pdf";
import ViewDownload from "../Components/ViewDownload";
import { useParams } from 'react-router-dom';
import Authuser from '../Authuser';
import Spinner from 'react-bootstrap/Spinner';
function    Timesheetsbyyear () {
    
    const [values,setValues]=useState({type:'',projecttitle:'',month:'',year:'',workhrs:'',document:''});
    const [errors, setErrors] = useState({type:'',projecttitle:'',month:'',year:'',workhrs:'',document:''});
    const [message,setMessage]= useState();
    const [smShow, setSmShow] = useState(false);
    const[loader,setLoader]=useState(false);
    const [months,setMonths]=useState(
        {
            '01': "Jan",'02': "Feb",'03': "Mar",'04': "Apr",'05': "May",'06': "Jun",'07': "Jul",'08': "Aug",'09': "Sep",'10': "Oct",'11': "Nov",'12': "Dec"
        });
    // Row Data: The data to be displayed.
    const [rowData, setRowData] = useState([]);
    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
        { headerName:"ProjectType", field: "type"}, 
        { headerName: "ProjectClientName", field: "projecttitle"},
        { headerName: "Month", field: "month"},
        { headerName: "Year", field: "year"}, 
        { headerName: "WorkingHours", field: "workhrs"},
        { headerName: "Attachment", field: "file_name",
            cellRenderer: Pdf,
        }, 
        { headerName : "SubmittedDateAndTime", field: "created_at"}, 

        

        { field: "",
            cellRenderer: ViewDownload,cellRendererParams: {
                folderName: 'empselftimesheets' // Pass folder name or other data as custom params,
                
            }
        },
    ]);
// ...
const defaultColDef = useMemo(() => {
    return {
        flex: 1, 
        filter: true,
    }
});
    const {month} = useParams();
    const { http, getUser } = Authuser();
    let userdetails = getUser();
    console.log(month)
    const [file, setFile] = useState(false);
    const [show, setShow] = useState(false);
    const [timesheetreports,setTimesheetreports]=useState([]);
    const [reportof,setReportof]=useState('');
    const [year,setYear]=useState('');
    const [modalshow, setModalshow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedid, setSelectedid] = useState(null);
    const now = new Date();
    const current = now.getFullYear();
    const previous = current - 1;
    function fetchItems(){
        if(month == current || month == previous){           
            var url = `/employeeselfservice/timesheetreport/${userdetails.email}?year=${month}`;
        }else{
            var url = `/employeeselfservice/timesheetreport/${userdetails.email}?id=${month}`;
        }
        http.get(url)
        .then((response) => {
            console.log(response.data);
            setRowData(response.data.timesheetreports);
            setReportof(response.data.reportof);
            setYear(response.data.year);
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    useEffect(() => {
        fetchItems();
        
    }, []);
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Subject
        if (!values.type.trim()) {
            newErrors.type = 'Project type is required';
            isValid = false;
        } else {
            newErrors.title = '';
        }
        // Message
        if (!values.projecttitle.trim()) {
            newErrors.projecttitle = 'Project title is required';
            isValid = false;
        } else {
            newErrors.projecttitle = '';
        }
        // File Upload
        if (!values.month.trim()) {
            newErrors.month = 'Month is required';
            isValid = false;
        } else {
            newErrors.month = '';
        }
        if (!values.year.trim()) {
            newErrors.year = 'Year is required';
            isValid = false;
        } else {
            newErrors.year = '';
        }
        if (!values.workhrs.trim()) {
            newErrors.workhrs = 'Work Hours required';
            isValid = false;
        } else {
            newErrors.workhrs = '';
        }
        if (!values.document.trim()) {
            newErrors.document = 'Document is required';
            isValid = false;
        } else {
            newErrors.document = '';
        }
        setErrors(newErrors);
        setMessage('');
        return isValid;
    };
    function handleChange(e){
        setMessage('');
        setValues({...values,[e.target.name]:e.target.value});
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        let type = values.type;
        let projecttitle = values.projecttitle;
        let month = values.month;
        let year = values.year;
        let workhrs = values.workhrs;
        let document = e.target.document.files[0];
        const newErrors = { ...errors };
        
        if (validateForm()) {
            setLoader(true);
          http.post(`/employeeselfservice/uploadtimesheet/${userdetails.email}`,{type:type,projecttitle:projecttitle,month:month,year:year,workhrs:workhrs,file_name:document,userid:userdetails.id}).then((response)=>{
              setValues({
                type: '',
                projecttitle: '',
                month: '',
                year: '',
                workhrs: '',
                document: '',
              });
              e.target.reset();
              setLoader(false);
              setMessage('Timesheet uploaded successfully!');
              fetchItems();
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
                newErrors.subject = error.response.data?.error?.subject;
                newErrors.document = error.response.data?.error?.document;
                newErrors.message = error.response.data?.error?.message;
                setErrors(newErrors);
            }
            if(error.response.status==401){
                newErrors.message = error.response.data?.error;
                setErrors(newErrors);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
   return (
        <Container fluid>
             <Breadcrumb className='mt-2'>
                <Breadcrumb.Item as={Link} to="/Admindashboard">Dashboard</Breadcrumb.Item>
               <Breadcrumb.Item active>  Timesheets </Breadcrumb.Item>
               
            </Breadcrumb>
            <Row className='m-1'>
                <Col sm={4} className='d-flex'>
                    <h6> Timesheets 2024</h6>
                </Col>
                <Col sm={4} className='pb-2'>
                </Col>
                <Col sm={4} className="d-flex justify-content-end">
                <Button variant="" onClick={() => setSmShow(true)} className='rounded-0 text-white' style={{backgroundColor:'#2F9DCC'}}>Submit Time Sheets</Button>
                </Col>
                <div className="ag-theme-quartz" // applying the grid theme
                style={{ height: 550 }} // the grid will fill the size of the parent container
                >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        defaultColDef={defaultColDef}
                       
                    />
                    </div>
           </Row>

           <Modal
        size="md"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm" className='fw-bold'>
          Submit Time Sheets
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form method="POST" onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <label>Project Type *</label>
                <Form.Select name="type" onChange={handleChange}  aria-label="Default select example">
                  <option value="">Select Project Type</option>
                  <option value="Internal">Internal</option>
                  <option value="External">External</option>                
                </Form.Select>
                <div style={{display:'block' }}  className="invalid-feedback small">{errors.type}</div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                    <label>Project / Client name *</label>
                <Form.Control type="text" name="projecttitle" onChange={handleChange} placeholder="Enter Project / Client name" />
                <div style={{display:'block' }}  className="invalid-feedback small">{errors.projecttitle}</div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <label>Month *</label>
                <Form.Select name="month" onChange={handleChange} aria-label="Default select example">
                    <option value="">Select Month</option>
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
      
                </Form.Select>
                <div style={{display:'block' }}  className="invalid-feedback small">{errors.month}</div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <label>Year *</label>
                <Form.Select name="year" required="" onChange={handleChange} aria-label="Default select example">
                  <option value="">Select Year *</option>
                  <option value={current}>{current}</option>
                  <option value={previous}>{previous}</option>
                </Form.Select>
                <div style={{display:'block' }}  className="invalid-feedback small">{errors.year}</div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                    <label>Total Working Hours *</label>
                <Form.Control type="text" name="workhrs" maxLength="6" placeholder="Total Working Hours *" onChange={handleChange}  />
                <div style={{display:'block' }}  className="invalid-feedback small">{errors.workhrs}</div>
              </Form.Group>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Submit approved time sheet *</Form.Label>
                <Form.Control type="file" name="document"  accept=".docx,application/pdf" id="formFile" onChange={handleChange} />
                <span>Submit approved time sheet (Word,Pdf,Excel,Jpg,Jpeg,Png files)</span>
                <div style={{display:'block' }}  className="invalid-feedback small">{errors.document}</div>
                <div style={{display:'block' }} className="alert-success small">{message}</div>
              </Form.Group>    
              <Col>
              </Col>
              <div className='text-center'>
              {loader === true &&
                  <Spinner animation="border" className='mx-auto' role="status">
                      <span className="visually-hidden">Loading...</span>
                  </Spinner>
                }
                <Button type="submit" style={{backgroundColor:'#2F9DCC'}} className='text-white ml-2'>Submit</Button>
              </div>
            </Form>
        </Modal.Body>
      </Modal>   
 </Container>

    );
}

export default   Timesheetsbyyear