import React,{useState,useEffect,useMemo} from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Authuser from '../Authuser';
function   InsuranceVendorInfo () {
    const{http,getUser} = Authuser();
    const [vendorinfo,setVendorinfo]=useState([]);
    let userdetails = getUser();
    function fetchData(){
        http.get(`/employeeselfservice/inxvendorinfo/${userdetails.email}`)
        .then((response) => {
        //console.log(response.data);
        setVendorinfo(response.data.inxvendorinfo);
        })
        .catch(function (error) {
        // handle error
        console.log(error.response.data.error);
        });
    }
  useEffect(() => {
    fetchData()
  },[]);
// Column Definitions: Defines the columns to be displayed.
const [colDefs, setColDefs] = useState([
    { field: "VendorName"}, 
    { field: "ContactName"},
     { field: "Email",
      
    }, 
    { field: "Mobile",  }, 
    { field: "InsuranceType",    
     

    },
       
  

   ]);

   
// ...
const defaultColDef = useMemo(() => {
    return {
        flex: 1, 
        filter: true,
      
        
    }
});
   return (
        <Container fluid>
             <Breadcrumb className='mt-2'>
                <Breadcrumb.Item as={Link} to="/">Dashboard</Breadcrumb.Item>
               <Breadcrumb.Item active> Insurance Vendor Info</Breadcrumb.Item>
               
            </Breadcrumb>
            <Row className='m-1'>
                <Col sm={4} className='d-flex'>
                    <h6> Insurance Vendor Info</h6>
                </Col>
                <Col sm={4} className='pb-2'>
                </Col>
                <Col sm={4} className="d-flex justify-content-end">
              
                </Col>
                <div className="ag-theme-quartz" // applying the grid theme
                style={{ height: 550 }} // the grid will fill the size of the parent container
                >
                    <AgGridReact
                        rowData={vendorinfo}
                        columnDefs={colDefs}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20]}
                        defaultColDef={defaultColDef}
                       
                    />
                    </div>
           </Row>
        </Container>

    );
}

export default  InsuranceVendorInfo