import React,{ useState, useEffect, useMemo } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import ViewDownload from "../Components/ViewDownload";
import Overdue from "../Components/Overdue";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import doc from '../Assets/doc.png';
import Authuser from '../Authuser';
import Spinner from 'react-bootstrap/Spinner';
function PerformanceReviewForm() {
  const [values,setValues]=useState({year:'',formqtr:'',document:''});
  const [errors, setErrors] = useState({year:'',formqtr:'',document:''});
  const [message,setMessage]= useState();
  const[loader,setLoader]=useState(false);
  const { http, getUser } = Authuser();
    let userdetails = getUser();
    //Date 
    const now = new Date();
    const current = now.getFullYear();
    const month= now.getMonth();
    const previous = current - 1;
    // End Date
    // Fetch Performance Review 
    const [cperformance, setCprerformance] = useState([]);
    const [viewprerformance, setViewprerformance] = useState([]);
    function fetchItems(){
        http.get(`/employeeselfservice/performancereview/${userdetails.email}`)
        .then((response) => {
            console.log(response.data);
            console.log("Performance Review Forms")
            setViewprerformance(response.data.previousreview);
            setCprerformance(response.data.performancereview);
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    useEffect(() => {
        fetchItems();
        
    }, []);
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Subject
        if (!values.year.trim()) {
            newErrors.year = 'Year is required';
            isValid = false;
        } else {
            newErrors.year = '';
        }
        // Message
        if (!values.formqtr.trim()) {
            newErrors.formqtr = 'Quarter is required';
            isValid = false;
        } else {
            newErrors.formqtr = '';
        }
        if (!values.document.trim()) {
            newErrors.document = 'Document is required';
            isValid = false;
        } else {
            newErrors.document = '';
        }
        setErrors(newErrors);
        setMessage('');
        return isValid;
    };
    function handleChange(e){
        setMessage('');
        setValues({...values,[e.target.name]:e.target.value});
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        let year = values.year;
        let formqtr = values.formqtr;
        let document = e.target.document.files[0];
        const newErrors = { ...errors };
        
        if (validateForm()) {
          setLoader(true);
          http.post(`/employeeselfservice/performanceform/${userdetails.email}`,{year:year,formqtr:formqtr,file_name:document,userid:userdetails.id}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              console.log(response.data.message);
              setValues({
                year: '',
                formqtr: '',
                document: '',
                
              });
              setLoader(false);
              setMessage('Performance review sent successfully!');
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
                newErrors.year = error.response.data?.error?.year;
                newErrors.formqtr = error.response.data?.error?.formqtr;
                newErrors.document = error.response.data?.error?.document;
                newErrors.message = error.response.data?.error?.message;
                setErrors(newErrors);
            }
            if(error.response.status==401){
                newErrors.message = error.response.data?.error;
                setErrors(newErrors);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
  const [smShow, setSmShow] = useState(false);
  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState([
    { Title: "Q4 (Oct to Dec 2023)", },
    { Title: "Q3 (Jul to Sep 2023)", },
    { Title: "Q2 (Apr to Jun 2023)", },
    { Title: "Q1 (Jan to Mar 2023)", },

  ]);
  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([
    { field: "Title" },
    {
      field: "",
      cellRenderer: ViewDownload,
      cellRendererParams: {
        folderName: 'empselfperformance' // Pass folder name or other data as custom params  
        ,columnname: 'Q4'      
      },
    },

    {
      field: "",
      cellRenderer: Overdue,
    },


  ]);
  // ...
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,


    }
  });
  return (
    <Container fluid>
      <Breadcrumb className='mt-2'>
        <Breadcrumb.Item as={Link} to="/Dashboard">Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item active> Performance Review Form</Breadcrumb.Item>

      </Breadcrumb>
      <Row className='m-1'>
        <div className="ag-theme-quartz m-auto" // applying the grid theme
          style={{ height: 400, width: 500, }} // the grid will fill the size of the parent container
        >
          <div className='text-center'>
            <div class="col-md-12 small m-auto text-center sm-doc">
              <div class=" col-md-5 i m-auto">
                <div class="c">
                  <div class="wrap p-2">
                    <div class="col-md-12 text-center mt-2" style={{ height: '60px' }}>

                      <img src={doc} alt='Document' className='img-fluid' style={{ width: 20 }} />
                    </div>
                    <div class="text-right small mt-3"></div>
                  </div>
                  <div class="more">
                    <div class="d-grid gap-2 mb-2">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Button variant="" onClick={() => setSmShow(true)} className='rounded-0 text-white mt-3' style={{ backgroundColor: '#3B86FF' }}>Submit Performance Review Form <i class="bi bi-arrow-right-short"></i></Button>
            <Button variant="" as={Link} to="/ViewAllPerformanceReviewForms" className='rounded-0 text-white mt-3 mb-3' style={{ backgroundColor: '#719F42' }}>View all performance review forms <i class="bi bi-arrow-right-short"></i></Button>
          </div>
          <h6> Performance Review Form</h6>

          <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
          />
        </div>

      </Row>
      <Modal
        size="md"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm" className='fw-bold'>
            <i class="bi bi-file-earmark-word"></i> Submit Performance Review Form
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form method="POST" onSubmit={handleSubmit}>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <label className='fw-bold'> Year *</label>
              <Form.Select name="year" onChange={handleChange} required>
                <option>Select Year</option>
                <option value={current}>{current}</option>
                <option value={previous}>{previous}</option>
              </Form.Select>
              <div style={{display:'block' }} className="invalid-feedback small">{errors.year}</div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <label className='fw-bold'> Quarter *</label>
              <Form.Select name="formqtr" onChange={handleChange}>
                <option value="">Select quarter *</option>
                <option value="Q1">Q1 (Jan to Mar)</option>
                <option value="Q2">Q2 (Apr to Jun)</option>
                <option value="Q3">Q3 (Jul to Sep)</option>
                <option value="Q4">Q4 (Oct to Dec)</option>
              </Form.Select>
              <div style={{display:'block' }}  className="invalid-feedback small">{errors.formqtr}</div>
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label className='fw-bold'>Upload Document</Form.Label>
              <Form.Control type="file" name="document"  accept=".docx,application/pdf" id="formFile" onChange={handleChange}/>
              <div style={{display:'block' }} className="invalid-feedback small">{errors.document}</div>
              <div style={{display:'block', color: "#1cc88a" }} className="alert-success small">{message}</div>
            </Form.Group>
            <Col>
            </Col>
            <div className='text-center'>
                {loader === true &&
                  <Spinner animation="border" className='mx-auto' role="status">
                      <span className="visually-hidden">Loading...</span>
                  </Spinner>
                }
              <Button type="submit" onClick={validateForm} style={{ backgroundColor: '#2F9DCC' }} className='text-white ml-2'>Submit</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>

  );
}

export default PerformanceReviewForm
