import React,{useState,useEffect} from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Support from '../Assets/Support_Center.svg';
import phone_call_icon from '../Assets/phone_call_icon.png';
import envelopeemailicon from '../Assets/envelope_email_icon.png';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Authuser from '../Authuser';
function Notificatons() {
  const {http,getUser}=Authuser();
  let userdetails = getUser();
  const [values,setValues]=useState({subject: '',description:''});
  const[errors,setErrors]=useState({subject:'',description:''});
  const[message,setMessage]=useState('');
  const handleChange = (e) =>{
    setValues({...values,[e.target.name]:e.target.value})
  }
  const validateForm = () =>{
    let isValid = true;
    const newErrors = { ...errors };
    const alphaRegex = /^[A-Za-z]+$/;
    // Validate email
    if (!values.subject.trim()) {
        newErrors.subject = 'Subject is required';
        isValid = false;
    } else  if(!alphaRegex.test(values.subject)){
      newErrors.subject = 'Alphabets allowed';
      isValid = false;
    }else {
        newErrors.subject = '';
    }

    // Validate password
    if (!values.description.trim()) {
        newErrors.description = 'Description is required';
        isValid = false;
    } else {
        newErrors.description = '';
    }

    setErrors(newErrors);
    return isValid;
  }
  const handleSubmit = (e) =>{
    e.preventDefault();
    let subject = values.subject;
    let description = values.description;
    if (validateForm()) {
      http.post(`/employeeselfservice/sendsupport/${userdetails.email}`,{subject:subject,description:description})
        .then((response)=>{
          setMessage("Suport request submitted");
          e.target.reset();
        })
        .catch(function (error){
          console.log(error)
          
        })
    }    
  }
  return (
    <Container fluid >
      <Container className='min-vh-75' >
        <Row> 
          <Card className='rounded-0 mt-4' style={{ width: '35rem', margin:'auto'}}>
            <Card.Body>
              <Row className='p-2'>
                <Col className='p-4 text-center roboto-medium' style={{ backgroundColor: '#E5F7FF'}}>
                  <img src={Support} alt='Support' className='img-fluid ' style={{ width: '2rem'}}/> SUPPORT CENTER
                </Col>
              </Row>
              <Row className='p-2'>
                <Col className='p-4 roboto-medium' xs={12} md={2}>
                  <img src={phone_call_icon} alt='Support' className='img-fluid ' style={{ width: '3rem'}}/>
                </Col>
                <Col className='p-4 roboto-medium' xs={12} md={10}>
                  +1-940-303-9299<br/>
                  <span className='small'>Talk to a Customer care . <br/>
                  We are here for you 9 am to 7 pm CT, Monday - Friday.
                  </span>
                </Col>
              </Row>
              <hr></hr>
              <Row className='p-2'>
                <Col className='p-4 roboto-medium' xs={12} md={2}>
                  <img src={envelopeemailicon} alt='Support' className='img-fluid ' style={{ width: '3rem'}}/>
                </Col>
                <Col className='p-4 roboto-medium' xs={12} md={10}>
                  Message Us<br/>
                  <span className='small'>Submit a question. We will respond to your inquiry as soon as possible.</span>
                </Col>
                <Form method="post" onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Subject *</Form.Label>
                    <Form.Control type="text" name="subject" onChange={handleChange} placeholder="Enter Subject" className='rounded-0' />
                    <div style={{display:'block'}} className="invalid-feedback small">{errors.subject}</div>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Description *</Form.Label>
                    <Form.Control as="textarea" rows={3} name="description" onChange={handleChange} className='rounded-0' placeholder='Please enter the details of your request. Our customer care person will respond as soon as possible.' />
                    <div style={{display:'block'}} className="invalid-feedback small">{errors.description}</div>
                    <div style={{display:'block', color: "#1cc88a" }} className="alert-success small">{message}</div> 
                  </Form.Group>
                  <Col className='text-center'>
                    <Button variant="primary" type="submit" onClick={validateForm} className='rounded-0'>Submit</Button>
                  </Col>
                </Form>
              </Row>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </Container>
);
}
  
    export default Notificatons