import React,{ useState,useEffect,useMemo }  from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Pdf from "../Components/Pdf";
import ViewDownload from "../Components/ViewDownload";
import Authuser from '../Authuser';
function    ViewPerformanceReviewForms () {
        const{http,getUser}= Authuser();
        let userdetails = getUser();
        // Row Data: The data to be displayed.
        const [rowData, setRowData] = useState([
            
        ]);
        // Column Definitions: Defines the columns to be displayed.
        const [colDefs, setColDefs] = useState([
            { headerName:"Q1(Jan to Mar)",field: "Q1", cellRenderer: ViewDownload,cellRendererParams: {
                folderName: 'empselfperformance' // Pass folder name or other data as custom params
                ,columnname: 'Q1'
            },}, 
            { headerName:"Q2(Apr to Jun)",field: "Q2",cellRenderer: ViewDownload,cellRendererParams: {
                folderName: 'empselfperformance' // Pass folder name or other data as custom params
                ,columnname: 'Q2'
            }}, 
            { headerName:"Q3(Jul to Sep)",field: "Q3",cellRenderer: ViewDownload,cellRendererParams: {
                folderName: 'empselfperformance' // Pass folder name or other data as custom params
                ,columnname: 'Q3'
            }}, 
            { headerName:"Q4(Oct to Dec)",field: "Q4",cellRenderer: ViewDownload,cellRendererParams: {
                folderName: 'empselfperformance' // Pass folder name or other data as custom params
                ,columnname: 'Q4'
            }}, 
            
            //{ headerName:"Year",field: "year"                }, 

            { headerName:"Year",field: "year",
                //cellRenderer: ViewDownload,
                cellStyle: { overflow: "visible", zIndex: "auto" },// Set this line
                resizable: true,
                suppressMenu: true,
                minWidth: 100,
                width: 80

            },
        ]);

        
        // ...
        const defaultColDef = useMemo(() => {
            return {
                flex: 1, 
                filter: true,
            
                
            }
        });
        function fetchItems(){        
            http.get(`/employeeselfservice/performancereview/${userdetails.email}`)
            .then((response) => {
                //console.log(response.data);
                setRowData(response.data.performancereviews);
            })
            .catch(function (error) {
                // handle error
                console.log(error.response.data.error);
            });
        }
        useEffect(() => {
            fetchItems();
        },[]);
   return (
        <Container fluid>
             <Breadcrumb className='mt-2'>
                <Breadcrumb.Item as={Link} to="/">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item active>  View All Performance Review Forms</Breadcrumb.Item>
               
            </Breadcrumb>
            <Row className='m-1'>
                <Col sm={4} className='d-flex'>
                    <h6> View All Performance Review Forms</h6>
                </Col>
                <Col sm={4} className='pb-2'>
                </Col>
                <Col sm={4} className="d-flex justify-content-end">
                
                </Col>
                <div className="ag-theme-quartz" // applying the grid theme
                style={{ height: 550 }} // the grid will fill the size of the parent container
                >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20]}
                        defaultColDef={defaultColDef}
                       
                    />
                    </div>
           </Row>

          
 </Container>

    );
}

export default  ViewPerformanceReviewForms