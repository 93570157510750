import './App.css';
import { Routes, Route } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import Authuser from './Authuser';
import Footer from './Components/Footer';
import Login from './Pages/Login';
import ForgotPassword from './Pages/ForgotPassword'
import Header from './Components/Header';
import Homenav from './Navbar/Homenav';
import Resetpassword from './Pages/Resetpassword';
function App() {
  const {getToken} = Authuser();
  if(!getToken()){
    return (
      <div className="App">
      <Routes>
        <Route path="/" exact element={<Login /> } />
        <Route path="/ForgotPassword" exact element={<ForgotPassword /> }/>
        <Route path="*" exact element={<Login /> }/>
        <Route path="/resetpassword/:token/:email" element={<Resetpassword pageview="Reset Password" apiurl="passwordUpdate"/>} />  
        
      </Routes> 
    </div>
    );
  }
  return (
      <div className='container-fluid'>          
        <Homenav />
        <Footer />
      </div>
  );
}

export default App;
