import React from 'react'
import {Routes,Route,Navigate} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../Assets/EmploymentExchange_Logo.svg';
import Form from 'react-bootstrap/Form';
import Networking from '../Assets/Networking.svg';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import support from '../Assets/Support_ico.svg';
import Authuser from '../Authuser';
import Dashboard from '../Pages/Dashboard';
import AllInvitees from '../Pages/AllInvitees';
import Feedback from '../Pages/Feedback';
import SupportCenter from '../Pages/SupportCenter';
import NewEmployeeDocumentation from '../Pages/NewEmployeeDocumentation';
import ViewAllReportingManagers from '../Pages/ViewAllReportingManagers';
import PerformanceReviewForm from '../Pages/PerformanceReviewForm';
import ViewAllPerformanceReviewForms from '../Pages/ViewAllPerformanceReviewForms';
import Timesheets from '../Pages/Timesheets';
import Timesheetsbyyear from '../Pages/Timesheetsbyyear';
import MyDocuments from '../Pages/MyDocuments';
import MyRequest from '../Pages/MyRequest';
import RequestHistory from '../Pages/RequestHistory';
import PayrollCalendar from '../Pages/PayrollCalendar';
import PaycheckLoginInfo from '../Pages/PaycheckLoginInfo';
import H1BDocumentation from '../Pages/H1BDocumentation';
import InsuranceVendorInfo from '../Pages/InsuranceVendorInfo';
import ReferralPolicy from '../Pages/ReferralPolicy';
import MyReferrals from '../Pages/MyReferrals';
import Suggestions from '../Pages/Suggestions';
import Notifications from '../Pages/Notifications';
import Messages from '../Pages/Messages';
import Changepassword from '../Pages/Changepassword';
import Myprofile from '../Pages/Myprofile';
import Myrequestlist from '../Pages/Myrequestlist';
import Viewallinvites from '../Pages/Viewallinvites';
function Homenav() {
  const {token,logout,getUser} = Authuser();
  let userdetails = getUser();
  const logoutUser = () =>{
    if(token !== undefined){
      logout();
    }
  }
  const isAuthenticated = (userdetails !== '') ? true : false;
  return (
    <>
      <nav className="navbar navbar-expand-lg" style={{backgroundColor:"#ffffff"}}>
        <div className="container-fluid">
        <Navbar.Brand  as={Link} to="/"><img src={logo} alt='Employment Exchange' className='img-fluid logo' style={{width:320}} /></Navbar.Brand>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <ul className="navbar-nav ms-md-auto gap-2 list-group-horizontal">           
            <li className="nav-item dropdown rounded ">
              <Nav.Link  className="nav-link dropdown-toggle " href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" > <i className="bi bi-grid-3x3-gap-fill" style={{fontSize:'14px'}}></i> <span style={{fontSize:'14px'}}>Services</span></Nav.Link >
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <Dropdown.Item as={Link} to="/">Home</Dropdown.Item>
                <Dropdown.Item as={Link} to="/NewEmployeeDocumentation">New Employee Documentation</Dropdown.Item>
                <Dropdown.Item as={Link} to="/ViewAllReportingManagers">My Reporting Manager</Dropdown.Item>
                <Dropdown.Item as={Link} to="/PerformanceReviewForm">Performance Review Overdue </Dropdown.Item>
                <Dropdown.Item as={Link} to="/Timesheets">Timesheets</Dropdown.Item>
                <Dropdown.Item as={Link} to="/MyDocuments">My Documents</Dropdown.Item>
                <Dropdown.Item as={Link} to="/MyRequest">My Request</Dropdown.Item>
                <Dropdown.Item as={Link} to="/PayrollCalendar">Payroll Calendar</Dropdown.Item>
                <Dropdown.Item as={Link} to="/PaycheckLoginInfo">Paychex Login Info</Dropdown.Item>
                <Dropdown.Item as={Link} to="/H1BDocumentation">H1b Documentation</Dropdown.Item>
                <Dropdown.Item as={Link} to="/InsuranceVendorInfo">Insurance Vendor Info</Dropdown.Item>
                <Dropdown.Item as={Link} to="/ReferralPolicy">Employee Referral Policy</Dropdown.Item>
                <Dropdown.Item as={Link} to="/MyReferrals">My Referrals</Dropdown.Item>
              </ul>
            </li>                    
          </ul>
          <div className="collapse navbar-collapse" id="navbarNav">        
          <Form className="me-auto my-2 my-lg-0">
            <Form.Control type="search" placeholder="Search" className="me-2 search rounded-0" aria-label="Search" />
          </Form>
          <ul className="navbar-nav ms-md-auto gap-2 list-group-horizontal">              
              <li className="nav-item rounded">
              <Nav.Link as={Link} to="/Connections" className='text-center roboto-light'><img src={Networking} alt='Networking' className='img-fluid'/> <br/>My Network</Nav.Link>
              </li>
              <li className="nav-item dropdown rounded mt-2">
              <Nav.Link  className="nav-link dropdown-toggle " href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <img src={support} alt='Employment Exchange' className='img-fluid' /></Nav.Link >
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <Dropdown.Item as={Link} to="/SupportCenter">Support Center</Dropdown.Item>
              <Dropdown.Item as={Link} to="/Feedback">Feedback</Dropdown.Item>
              <Dropdown.Item as={Link} to="/Askmeanything">Ask me anything</Dropdown.Item>
                </ul>
              </li>
              <li className="nav-item rounded">
              <ul className="notification-drop">
                <li className="item">
                <Nav.Link as={Link} to="/Messages"> <i className="bi bi-chat-left-fill"></i>
              <span className="btn__badge pulse-button ">4</span> </Nav.Link>
                </li>
        </ul>           
              </li>

              <li className="nav-item rounded">
              <ul className="notification-drop">
                <li className="item">
                <Nav.Link > <i className="bi bi-bell-fill"></i>
              <span className="btn__badge pulse-button ">4</span>  </Nav.Link>   
              <ul className='shadow'>
              <li>David Lee <br/>
                <span className='roboto-light'>Hey, I just read your message. How are you?</span>
                </li>
                <li>David Lee <br/>
                <span className='roboto-light'>Hey, I just read your message. How are you?</span>
                </li>
                <li>David Lee <br/>
                <span className='roboto-light'>Hey, I just read your message. How are you?</span>
                </li>
                <li>David Lee <br/>
                <span className='roboto-light'>Hey, I just read your message. How are you?</span>
                </li>
                <li>David Lee <br/>
                <span className='roboto-light'>Hey, I just read your message. How are you?</span>
                </li>
                <li>
                <span className='roboto-light text-center'> <Nav.Link as={Link} to="/Notifications">View All</Nav.Link></span>
                </li>
            </ul>
          </li>
        </ul>
              </li>
              <li className="nav-item dropdown  pt-2 ml-2" style={{borderLeft:'1px solid #D5D5D5'}}>
              <Nav.Link className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-caret-down-fill"></i></Nav.Link>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                <Dropdown.Item><span className='fw-bold'>{userdetails?.firstname} {userdetails?.lastname}</span></Dropdown.Item>
                <Dropdown.Item as={Link} to="/Myprofile">Profile</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/Changepassword">Change Password  </Dropdown.Item>
            <hr></hr>
                    <Dropdown.Item onClick={logoutUser}>Logout</Dropdown.Item>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Routes>
        <Route path="/" exact element={ isAuthenticated ? <Dashboard/> : <Navigate to="/" replace /> }/>
        <Route path="/AllInvitees" exact element={ isAuthenticated ? <AllInvitees/> : <Navigate to="/" replace /> } />
        <Route path="/Feedback" exact element={ isAuthenticated ? <Feedback/>  : <Navigate to="/" replace /> }  />
        <Route path="/SupportCenter" exact element={ isAuthenticated ? <SupportCenter/> : <Navigate to="/" replace /> } />
        <Route path="/NewEmployeeDocumentation" exact element={ isAuthenticated ? <NewEmployeeDocumentation/> : <Navigate to="/" replace /> }  />
        <Route path="/ViewAllReportingManagers" exact element={ isAuthenticated ? <ViewAllReportingManagers/> : <Navigate to="/" replace /> }  />
        <Route path="/PerformanceReviewForm" exact element={ isAuthenticated ? <PerformanceReviewForm/> : <Navigate to="/" replace /> } />
        <Route path="/ViewAllPerformanceReviewForms" exact element={ isAuthenticated ? <ViewAllPerformanceReviewForms/> : <Navigate to="/" replace /> } />
        <Route path="/Timesheets" exact element={ isAuthenticated ? <Timesheets/> : <Navigate to="/" replace /> } />
        <Route path="/Timesheetsbyyear/:month" exact element={ isAuthenticated ? <Timesheetsbyyear/> : <Navigate to="/" replace /> } />
        <Route path="/MyDocuments" exact element={ isAuthenticated ? <MyDocuments/> : <Navigate to="/" replace /> } />
        <Route path="/MyRequest" exact element={ isAuthenticated ? <MyRequest/> : <Navigate to="/" replace /> } />
        <Route path="/RequestHistory" exact element={ isAuthenticated ? <RequestHistory/> : <Navigate to="/" replace /> }  />
        <Route path="/PayrollCalendar" exact element={ isAuthenticated ? <PayrollCalendar/> : <Navigate to="/" replace /> } />
        <Route path="/PaycheckLoginInfo" exact element={ isAuthenticated ? <PaycheckLoginInfo/> : <Navigate to="/" replace /> } />
        <Route path="/H1BDocumentation" exact element={ isAuthenticated ? <H1BDocumentation/> : <Navigate to="/" replace /> } />
        <Route path="/InsuranceVendorInfo" exact element={ isAuthenticated ? <InsuranceVendorInfo/>  : <Navigate to="/" replace /> } />
        <Route path="/ReferralPolicy" exact element={ isAuthenticated ? <ReferralPolicy/> : <Navigate to="/" replace /> } />
        <Route path="/MyReferrals" exact element={ isAuthenticated ? <MyReferrals/> : <Navigate to="/" replace /> } />
        <Route path="/Suggestions" exact element={ isAuthenticated ? <Suggestions/>  : <Navigate to="/" replace /> } />
        <Route path="/Notifications" exact element={ isAuthenticated ? <Notifications/> : <Navigate to="/" replace /> } />
        <Route path="/Messages" exact element={ isAuthenticated ? <Messages/> : <Navigate to="/" replace /> }  />
        <Route path="/Changepassword" exact element={ isAuthenticated ? <Changepassword/> : <Navigate to="/" replace /> } />
        <Route path="/Myprofile" exact element={ isAuthenticated ? <Myprofile/>  : <Navigate to="/" replace /> }  />
        <Route path="/Myrequestlist" exact element={ isAuthenticated ? <Myrequestlist /> : <Navigate to="/" replace /> }  />
        <Route path="/Viewallinvites" exact element={ isAuthenticated ? <Viewallinvites /> : <Navigate to="/" replace /> }  />
        
      </Routes>
    </>
  )
}

export default Homenav