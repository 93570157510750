import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Header from '../Components/Header';
import Footer from '../Components/Footer';


function Suggestions() {

  return (
    <Container fluid>
      <Header/>
    <Container className='mt-1'>
        <Row className='min-vh-75'>
        <Card className='rounded-0' style={{ width: '35rem', margin:'auto'}}>
      <Card.Body>
<Row className='p-1'>
<Col className='p-4 roboto-medium border-bottom'>
Suggestions
</Col>

</Row>

<Row className='p-2'>

<Form>
    <p>Help us improve our product? Give us your valuable suggestions.</p>
<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label className='roboto-bold'>Subject*</Form.Label>
        <Form.Control as="text" rows={3} className='rounded-0' placeholder='Enter Subject'/>             
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label className='roboto-bold'>Reference URL*</Form.Label>
        <Form.Control as="text" rows={3} className='rounded-0' placeholder='Enter Reference URL'/>             
      </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label className='roboto-bold'>Comments *</Form.Label>
        <Form.Control as="textarea" rows={3} className='rounded-0' placeholder='Comments...' />
              
      </Form.Group>
      
      
      <Col className='text-center'>
        
      <Button variant="primary" className='rounded-0'>Submit</Button>
      </Col>
    </Form>
</Row>
      </Card.Body>
    </Card>
</Row>

</Container>
<Footer/>

 
</Container>
);
}
  
    export default Suggestions