import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { useState,useEffect,useMemo } from "react";


function PayrollCalendar(){

  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState([
    { PayPeriod: "Jan 01 - Jan 31 /2024", PayDate: "Last working day of Feb/2024",   },
    { PayPeriod: "Feb 01 - Feb 29 /2024", PayDate: "Last working day of Mar/2024",   },
    { PayPeriod: "Mar 01 - Mar 31 /2024", PayDate: "Last working day of Apr/2024",   },
    { PayPeriod: "Apr 01 - Apr 30 /2024", PayDate: "Last working day of May/2024",   },
    { PayPeriod: "May 01 - May 31 /2024", PayDate: "Last working day of Jun/2024",   },
    { PayPeriod: "Jun 01 - Jun 30 /2024", PayDate: "Last working day of Jul/2024",   },
    { PayPeriod: "Jul 01 - Jul 31 /2024", PayDate: "Last working day of Aug/2024",   },
    { PayPeriod: "Aug 01 - Aug 31 /2024", PayDate: "Last working day of Sep/2024",   },
    { PayPeriod: "Sep 01 - Sep 30 /2024", PayDate: "Last working day of Oct/2024",   },
    { PayPeriod: "Oct 01 - Oct 31 /2024", PayDate: "Last working day of Nov/2024",   },
    { PayPeriod: "Nov 01 - Nov 30 /2024", PayDate: "Last working day of Dec/2024",   },
    { PayPeriod: "Dec 01 - Dec 31 /2024", PayDate: "Last working day of Jan/2025",   },
    
   
]);
// Column Definitions: Defines the columns to be displayed.
const [colDefs, setColDefs] = useState([
    { field: "PayPeriod"}, 
    { field: "PayDate"},
     
   
       
   
   ]);

   
// ...
const defaultColDef = useMemo(() => {
    return {
        flex: 1, 
        filter: true,
      
        
    }
});
   return (
        <Container fluid>
             <Breadcrumb className='mt-2'>
                <Breadcrumb.Item as={Link} to="/">Dashboard</Breadcrumb.Item>
                              <Breadcrumb.Item active>Payroll Calendar</Breadcrumb.Item>
               
            </Breadcrumb>
            <Row className='m-1'>
                <Col sm={4} className='d-flex'>
                    <h6>Payroll Calendar</h6>
                </Col>
                <Col sm={4} className='pb-2'>
                </Col>
                <Col sm={4} className="d-flex justify-content-end">
              
                </Col>
                <div className="ag-theme-quartz m-auto" // applying the grid theme
                style={{ height: 550,width:500, }} // the grid will fill the size of the parent container
                >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                      
                        defaultColDef={defaultColDef}
                       
                    />
                    </div>
           </Row>

           <Footer/>    
 </Container>

    );
}

export default   PayrollCalendar