import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

const H1BDocumentation = () => {
   
    return (
        <Container fluid>
            <Breadcrumb className='mt-2'>
                <Breadcrumb.Item as={Link} to="/Dashboard">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item active> H1B Documentation  </Breadcrumb.Item>
                
            </Breadcrumb>
            <Container className='mt-3'>

                               <Container className='bg-white p-4 border text-center'>
             
                    <Row>
                        <h6 className='fw-bold text-center'>H1B Documentation </h6>
                  <hr></hr>
                  <p className='fw-bold'>Applicants can view, upload documents and create tickets</p>  
                  <Card.Link href="https://www.h1bapplicants.com/">https://www.h1bapplicants.com/</Card.Link> 
               
                    </Row>
                </Container>
              
            </Container>
        </Container>
    );
}

export default H1BDocumentation