
import axios from 'axios';
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
export default function Authuser() {
  const navigate = useNavigate();
  //const apiurl="http://127.0.0.1:8000/";
  const apiurl="https://api.employmentexchange.com/";
  const getToken = () =>{
      const tokenString = sessionStorage.getItem('token');
      const userToken = JSON.parse(tokenString);
      return userToken;
  }

  const getUser = () =>{
      const userString = sessionStorage.getItem('user');
      const user_detail = JSON.parse(userString);
      return user_detail;
  }

  const getUsertype = () =>{
      const userString = sessionStorage.getItem('usertype');
      const user_type = JSON.parse(userString);
      return user_type;
  }


  const [token,setToken] = useState(getToken());
  const [user,setUser] = useState(getUser());
  //const [usertype,setUsertype]=useState(getUsertype());
  const saveToken = (user,token) =>{
      sessionStorage.setItem('user',JSON.stringify(user));
      sessionStorage.setItem('token',JSON.stringify(token));
      setToken(token);
      setUser(user);
      navigate(`/`);
  }
  const saveUser = (user) =>{
      sessionStorage.setItem('user',JSON.stringify(user));
      setUser(user);
      
  }
  const logout = () => {
      sessionStorage.clear();
      //navigate('/',{clearPreviousHistory:true});
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function () {
        window.history.go(1);
      };
      navigate('/',{replace:true});
  }

  const http = axios.create({
      //baseURL:"http://api.h1bapplicants.com/api", //http://127.0.0.1:8000/api
      baseURL:`${apiurl}api`,
      headers:{
          "Access-Control-Allow-Origin": "*",
          "Content-type" : "application/json,multipart/form-data,blob", //,
          "Authorization" : `Bearer ${token}`,
      }
  });
  return {
      setToken:saveToken,
      setUser:saveUser,
      token,
      user,
      //usertype,
      getToken,
      getUsertype,
      getUser,
      http,
      logout,
      apiurl
  }
}
