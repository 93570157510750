import React,{useState,useEffect} from 'react'
import Authuser from '../Authuser'
function Overdue(props) {
  
  const { http, getUser } = Authuser();
    let userdetails = getUser();
    //Date 
    const now = new Date();
    const current = now.getFullYear();
    const month= now.getMonth();
    const previous = current - 1;
    // End Date
    // Fetch Performance Review 
    const [cperformance, setCprerformance] = useState([]);
    const [viewprerformance, setViewprerformance] = useState([]);
    function fetchItems(){
        http.get(`/employeeselfservice/performancereview/${userdetails.email}`)
        .then((response) => {
           
            setViewprerformance(response.data.previousreview);
            setCprerformance(response.data.performancereview);
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    useEffect(() => {
        fetchItems();
        
    }, []);
  return (
    <>
    {
      viewprerformance.Q4 !='NULL' ? 
      <span class="badge rounded-pill p-1 text-success " style={{border: '2px solid #719f42'}}>
        <span class="blink"> Submited </span>
      </span>
      :
      <span class="badge rounded-pill p-1 text-danger " style={{border: '2px solid #E61414'}}>
        <span class="blink"> Overdue </span>
      </span>
              
    }
      
      
    </>
    
  )
}

export default Overdue