import React,{useState} from 'react'
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Authuser from '../Authuser';
import Spinner from 'react-bootstrap/Spinner';
function MyRequest() {
  const [values,setValues]=useState({subject:'',message:'',document:''});
  const [errors, setErrors] = useState({subject: '',message: '',document:'',errormessage:'' });
  const { http, getUser } = Authuser();
  const [message,setMessage]= useState();
  const[loader,setLoader]=useState(false);
  let userdetails = getUser();
  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `newPath`; 
    navigate('/Myrequestlist');
  }
  const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Subject
        if (!values.subject.trim()) {
            newErrors.subject = 'Subject is required';
            isValid = false;
        } else {
            newErrors.title = '';
        }
        // Message
        if (!values.message.trim()) {
            newErrors.message = 'Message is required';
            isValid = false;
        } else {
            newErrors.message = '';
        }
        // File Upload
        if (!values.document.trim()) {
            newErrors.document = 'Document is required';
            isValid = false;
        } else {
            newErrors.document = '';
        }

        setErrors(newErrors);
        setMessage('');
        return isValid;
    };
    function handleChange(e){
        setMessage('');
        setValues({...values,[e.target.name]:e.target.value});
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        let subject = values.subject;
        let message = values.message;
        let document = e.target.document.files[0];
        const newErrors = { ...errors };
        
        if (validateForm()) {
          setLoader(true);
          http.post(`/employeeselfservice/postrequest/${userdetails.email}`,{subject:subject,file:document,message:message,userid:userdetails.id}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              console.log(response.data.message);
              setValues({
                subject: '',
                document: '',
                message: '',
              });
              e.target.reset();
              setLoader(true);
              setMessage('Request sent successfully!');
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
                newErrors.subject = error.response.data?.error?.subject;
                newErrors.document = error.response.data?.error?.file;
                newErrors.message = error.response.data?.error?.message;
                setErrors(newErrors);
            }
            if(error.response.status==413){
              newErrors.errormessage = error.response.data?.error?.message;
              setErrors(newErrors);
            }
            if(error.response.status==401){
                newErrors.message = error.response.data?.error;
                setErrors(newErrors);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
  return (
    <Container fluid>
      <Container className='mt-1'>
        <Row className='min-vh-75'>
          <Card className='rounded-0' style={{ width: '35rem', margin:'auto'}}>
            <Card.Body>
              <Row className='p-1'>
                <Col className='p-4 roboto-medium border-bottom'>My Request</Col>
                <Col className="d-flex justify-content-end"><Button variant="" onClick={routeChange} className='rounded-0 text-white' style={{backgroundColor:'#2F9DCC'}}>Request  History</Button></Col>
              </Row>
              <Row className='p-2'>
                <Form method="POST" onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label className='roboto-bold'>Subject*</Form.Label>
                    <Form.Control type="text" name="subject" onChange={handleChange}  rows={3} className='rounded-0' placeholder='Enter Subject' /> 
                    <div style={{display:'block', color: "#bc3f34" }} className="invalid-feedback small">{errors.subject}</div>            
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label className='roboto-bold'>Message*</Form.Label>
                    <Form.Control type="textarea" name="message" onChange={handleChange}  rows={3} className='rounded-0' />
                    <p className='small text-muted'>1000 character(S) available. Do not disclose any personal, commercially sensitive, or confidential information.</p>
                    <div style={{display:'block', color: "#bc3f34" }} className="invalid-feedback small">{errors.message}</div>
                  </Form.Group>
                      
                  {['radio'].map((type) => (
                    <div key={`inline-${type}`}>
                          
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label className='roboto-bold'>File attachment</Form.Label>
                        <Form.Control type="file" name="document" onChange={handleChange} className='rounded-0' />   
                        <div style={{display:'block', color: "#bc3f34" }} className="invalid-feedback small">{errors.document}</div>
                        <div style={{display:'block', color: "#bc3f34" }} className="invalid-feedback small">{errors.errormessage}</div>
                        <div className="invalid-feedback small">{errors.successmessage}</div>
                        <div className="alert-success small">{message}</div>                   
                      </Form.Group> 
                    </div>
                  ))}
                  {loader === true &&
                      <Spinner animation="border" className='mx-auto' role="status">
                          <span className="visually-hidden">Loading...</span>
                      </Spinner>
                  } 
                  <Col className='text-center'><Button variant="primary" type="submit" onClick={validateForm} className='rounded-0'>Submit</Button></Col>
                </Form>
              </Row>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </Container>
  );
}  
export default MyRequest