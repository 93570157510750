import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
function PaycheckLoginInfo(){
   
    return (
        <Container fluid>
            <Breadcrumb className='mt-2'>
                <Breadcrumb.Item as={Link} to="/">Dashboard</Breadcrumb.Item>
                 <Breadcrumb.Item active> Paycheck Login Info  </Breadcrumb.Item>                
            </Breadcrumb>
            <Container className='mt-3'>
                <Container className='bg-white p-4 border text-center'>             
                    <Row>
                        <h6 className='fw-bold text-center'>Paycheck Login Info</h6>
                        <hr></hr>
                        <p className='fw-bold'>Create Account at</p>  
                        <Card.Link href="https://myapps.paychex.com">https://myapps.paychex.com</Card.Link>                  
                        <p>Sign in with your created user id and password to view pay stubs and tax documents. </p>
                    </Row>
                </Container>              
            </Container>
        </Container>
    );
}

export default PaycheckLoginInfo