import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Authuser from "../Authuser";
function Feedback() {
  const { http, getUser } = Authuser();
  let userdetails = getUser();
  const [values, setValues] = useState({errortype:'',message:'',experience:'',attachment:''});
  const [errors, setErrors] = useState({errortype:'',message:'',experience:'',attachment:''});
  const [successMessage, setSuccessMessage] = useState('');
  function handleChange(e){
    setSuccessMessage('');
      setValues({...values,[e.target.name]:e.target.value});
  }
  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };
    // Subject
    if (!values.errortype.trim()) {
        newErrors.errortype = 'Error type is required';
        isValid = false;
    } else {
        newErrors.errortype = '';
    }
    // Message
    if (!values.message.trim()) {
        newErrors.message = 'Message is required';
        isValid = false;
    } else {
        newErrors.message = '';
    }
    if (!values.experience.trim()) {
        newErrors.experience = 'Experience is required';
        isValid = false;
    } else {
        newErrors.experience = '';
    }
    if (!values.attachment.trim()) {
        newErrors.attachment = 'Attachment is required';
        isValid = false;
    } else {
        newErrors.attachment = '';
    }

    setErrors(newErrors);
    setSuccessMessage('');
    return isValid;
  };

  const handleSubmit = async(e) =>{
    e.preventDefault();
    let errortype = values.errortype;
      let message = values.message;
      let experience = values.experience;
      let attachment = e.target.attachment.files[0];
      const newErrors = { ...errors };
    
    if (validateForm()) {
      
      http.post(`/employeeselfservice/feedback/${userdetails.email}`,{errortype:errortype,message:message,experience:experience,attachment:attachment,userid:userdetails.id}).then((response)=>{
          //setToken(response.data.userdetail,response.data.token,usertype);
          //console.log(response.status);
          console.log(response.data.message);
          setValues({
            errortype: '',
            message: '',
            experience: '',
            attachment: '',
          });
          e.target.reset();
          setSuccessMessage('Feedback sent successfully!');
          
      }).catch(function (error) {
        // handle error
        //console.log(error.response.data.error);
        if(error.response.status==403){
            newErrors.errortype = error.response.data?.error?.errortype;
            newErrors.message = error.response.data?.error?.message;
            newErrors.experience = error.response.data?.error?.experience;
            newErrors.attachment = error.response.data?.error?.attachment;
            setErrors(newErrors);
        }
        if(error.response.status==401){
            newErrors.message = error.response.data?.error;
            setErrors(newErrors);
        }
        //setsubmitted(false)
      })
    } else {
      // Form is not valid, display error messages

    }
  }
  return (
    <Container fluid>
      <Container className="mt-1">
        <Row className="min-vh-75">
          <Card
            className="rounded-0"
            style={{ width: "35rem", margin: "auto" }}
          >
            <Card.Body>
              <Row className="p-1">
                <Col className="p-4 roboto-medium border-bottom">Feedback</Col>
              </Row>

              <Row className="p-2">
                <p>Thank you for taking time to provide feedback.</p>
                <Form method="post" onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label className="roboto-bold">Type *</Form.Label>
                    <br />
                    <span className="small">
                      Choose the type of feedback you are submitting{" "}
                    </span>
                    <Form.Select aria-label="Default select example" className="rounded-0" name="errortype" onChange={handleChange}>
                      <option value="">Select type of feedback</option>
                      <option value="General feedback">General feedback</option>
                      <option value="Feature request">Feature request</option>
                      <option value="Report an issue">Report an issue</option>
                      <option value="UI feedback">UI feedback</option>
                    </Form.Select>
                    <div style={{display:'block' }} className="invalid-feedback small">{errors.errortype}</div>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label className="roboto-bold">Enter your message below *</Form.Label>
                    <Form.Control as="textarea" rows={3} className="rounded-0" name="message" onChange={handleChange} />
                    <p className="small text-muted">
                      1000 character(S) available. Do not disclose any personal,
                      commercially sensitive, or confidential information.
                    </p>
                    <div style={{display:'block' }} className="invalid-feedback small">{errors.message}</div>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label className="roboto-bold">Are you satisfied with your experience?</Form.Label>
                    {["radio"].map((type) => (
                      <div key={`inline-${type}`}>
                        <Form.Check inline label="Yes" name="experience" value="Yes" onChange={handleChange} checked={values.experience === "Yes"} type={type} id={`inline-${type}-1`} />
                        <Form.Check inline label="No" name="experience"  value="No" checked={values.experience === "No"} onChange={handleChange} type={type} id={`inline-${type}-2`} />
                        <div style={{display:'block' }} className="invalid-feedback small">{errors.experience}</div>                      
                      </div>
                    ))}
                  </Form.Group>
                  
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label className="roboto-bold">File attachment</Form.Label>
                    <Form.Control type="file" name="attachment" onChange={handleChange} accept=".docx,application/pdf" id="formFile" className="rounded-0" />
                    <span className="small">Please don't attach images with PII Information</span>
                    <div style={{display:'block' }} className="invalid-feedback small">{errors.attachment}</div>
                    <div style={{display:'block', color: "#1cc88a" }} className="alert-success small">{successMessage}</div>
                  </Form.Group>
                  <Col className="text-center">
                    <Button type="submit" onClick={validateForm} variant="primary" className="rounded-0">
                      Submit
                    </Button>
                  </Col>
                </Form>
              </Row>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </Container>
  );
}

export default Feedback;
