import React,{useState} from "react";
import { CardLink } from 'react-bootstrap';
import axios from "axios";
import ViewDocument from "./ViewDocument";
import Authuser from "../Authuser";
function ViewDownload(props) {
    const{http} = Authuser();
    
    const { folderName, data, columnname } = props;
    let viewfile = '';
    const [modalshow, setModalshow] = useState(false);
    const [file, setFile] = useState(false);
    const handleModalShow = (filename) =>{
        //console.log(filename)
        setModalshow(true);
        setFile(filename);
    } 
    
    
    // Download file
    const handleDownload = (filename) => {
        
        http.post(`/employeeselfservice/downloadFiles`,{files:filename,folderName:folderName}, {
            responseType: 'blob',
        })
        .then((response) => {
            //console.log(response);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url; //''
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
        
      };
      if(folderName === 'empselfperformance'){
        let status = false;
        if(columnname == 'Q1'){
            viewfile = data.Q1;
        }else if(columnname == 'Q2'){
            viewfile = data.Q2;
        }else if(columnname == 'Q3'){
            viewfile = data.Q3;
        }else if(columnname == 'Q4'){
            viewfile = data.Q4;
        }
        if(viewfile !== 'NULL'){
            return (
                <div>
                    <CardLink  href="#" onClick={() => handleModalShow(folderName === 'empselfperformance' ?  viewfile: data.file_name)} >View</CardLink> | 
                    <CardLink  onClick={() => handleDownload(folderName === 'empselfperformance' ? viewfile : data.file_name)}  href="#"> Download</CardLink>
                    <ViewDocument show={modalshow} filename={file} foldertype={folderName} close={() => setModalshow(false)}/>
                </div>
            )
        }
        
      }else{
        return (
            <div>
                <CardLink  href="#" onClick={() => handleModalShow(folderName == 'empselfdocs' ? data.essdocument : data.file_name)} >View</CardLink> | 
                <CardLink  onClick={() => handleDownload(folderName == 'empselfdocs' ? data.essdocument : data.file_name)}  href="#"> Download</CardLink>
                <ViewDocument show={modalshow} filename={file} foldertype={folderName} close={() => setModalshow(false)}/>
            </div>
        )
      }
    
}

export default ViewDownload


