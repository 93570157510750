import React,{ useState,useEffect,useMemo } from 'react'
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Authuser from '../Authuser';
function Employeedashboard(){
    const [smShow, setSmShow] = useState(false);
    const[userprofile,setUserprofile]=useState({});
    const [email,setEmail]=useState('');
    const [error, setError] = useState('');
    const[message,setMessage]=useState();
    const {http,getUser} = Authuser();
    let userdetails = getUser();
    function handleChange(e){
        setEmail(e.target.value)
    }
    let navigate = useNavigate(); 
    const routeChange = () =>{ 
      let path = `newPath`; 
      navigate('/Viewallinvites');
    }
    useEffect(() =>{
      http.get(`/employeeselfservice/dashboard/${userdetails.email}`)
      .then((response) => {
          setUserprofile(response.data.userprofile);
          
      })
      .catch(function (error) {
          // handle error

          console.log(error.response.data.error);
      });
    },[])
    const validateForm = () => {
        let isValid = true;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let emailerror;
        // Validate email
        if (!email.trim()) {
            emailerror = 'Email is required';
            isValid = false;
        } else  if(!emailRegex.test(email)){
            emailerror = 'Email not valid';
            isValid = false;
        }else {
            emailerror = '';
        }

        
        setError(emailerror);
        return isValid;
    };
    const handleSubmit = async (e) =>{
        e.preventDefault();
        //validation(values);
        let email = e.target.useremail.value;
        let emailerror;
        if (validateForm()) {
          http.post(`/employeeselfservice/postinvite/${userdetails.email}`,{invited_to:email,userid:userdetails.id}).then((response)=>{
            setMessage('Invitation sent!');
              //console.log(response.data);
              
          }).catch(function (error) {
            // handle error
            if(error?.response?.status==422){
              error = error.response.data?.errors?.invited_to;
              setError(error);
            }
            console.error(error)
            //console.log(error.response.data.error);
            
            if(error?.response?.status==403){
              error = error.response.data?.error?.invited_to;
              setError(error);
            }
            if(error?.response?.status==401){
                error = error.response.data?.error;
                setError(error);
            }
            
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
  return (
    <Container fluid>
      <Container className='mt-3'>   
      <Row>
        <Col><h6 className='fw-bold pt-2'>Home</h6></Col>
        <Col className="d-flex justify-content-end">

        <Button variant="light" onClick={routeChange} className='rounded-0 border shadow-sm '>View all Invitees</Button>
        <Button variant="primary" className='rounded-0 ml-2 shadow-sm'onClick={() => setSmShow(true)} > Invite a friend</Button>
        </Col>
      </Row>
        <Row className='mt-3'>
          <Col sm={12} md={12} lg={4}>         
          <Card className='rounded-0 shadow-sm' style={{minHeight:'455px'}}>
      <Card.Header className='card-title'>Employee</Card.Header>
      <Card.Body>
     <Card.Text>
     <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     >  <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Date of Join</div>
         <span className='text-muted'>{userdetails.created_at}</span>
       </div></Card.Link>
       </ListGroup.Item>
       <hr></hr>
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Role</div>
         <span className='text-muted'>{userprofile?.designation}</span>
       </div>
       </Card.Link>
       </ListGroup.Item>
       <hr></hr>
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Employee ID</div>
         <span className='text-muted'>{userprofile?.employeeid}</span>
       </div>
       </Card.Link>
       </ListGroup.Item>
       <hr></hr>
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Date of Birth</div>
         <span className='text-muted'>{userprofile?.dateofbirth}</span>
       </div>
       </Card.Link>
       </ListGroup.Item>
       <hr></hr>
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Gender</div>
         <span className='text-muted'>{userdetails.gender}</span>
       </div>
       </Card.Link>
       </ListGroup.Item>
       </Card.Text>
       </Card.Body>
    </Card>
          </Col>
          <Col sm={12} md={12} lg={4}>
          <Card className='rounded-0 shadow-sm' style={{minHeight:'455px'}}>
      <Card.Header className='card-title'>Location</Card.Header>
      <Card.Body>
     <Card.Text>
     <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     >  <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Address</div>
         <span className='text-muted'>{userprofile?.addressline1}{userprofile?.addressline2} {userprofile?.city} {userprofile?.state} {userprofile?.country}</span>
       </div></Card.Link>
       </ListGroup.Item>
       <hr></hr>
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Email</div>
         <span className='text-muted'>{userdetails.email}</span>
       </div>
       </Card.Link>
       </ListGroup.Item>
       <hr></hr>
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Mobile No</div>
         <span className='text-muted'></span>
       </div>
       </Card.Link>
       </ListGroup.Item>
          
       
       
       </Card.Text>
       </Card.Body>
    </Card>
          </Col>
          <Col sm={12} md={12} lg={4}>
     <Card className='rounded-0 shadow-sm '>
     <Card.Header className='card-title'>
      <Row>
        <Col>
        <div className=' d-flex justify-content-start'>Holidays   </div>  
        </Col>
        <Col>
        
        </Col>
      </Row>
      
    
      </Card.Header>
     <Card.Body>
     <Card.Text>
     <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     >  <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">New year's Day</div>
         <span className='text-muted'>Jan 01, 2024</span>
       </div></Card.Link>
       </ListGroup.Item>
       <hr></hr>
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Memorial Day</div>
         <span className='text-muted'>May 29,2024</span>
       </div>
       </Card.Link>
       </ListGroup.Item>
       <hr></hr>
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Independence Day</div>
         <span className='text-muted'>Jul 04, 2024</span>
       </div>
       </Card.Link>
       </ListGroup.Item> 
       <hr></hr>
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Labor Day</div>
         <span className='text-muted'>May 29, 2024</span>
       </div>
       </Card.Link>
       </ListGroup.Item> 
       <hr></hr> 
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Veterans Day  </div>
         <span className='text-muted'>Nov 11, 2024</span>
       </div>
       </Card.Link>
       </ListGroup.Item> 
       </Card.Text>
       </Card.Body>
   </Card>
         </Col>
        </Row>
       <Row className='mt-4'>
    
        
          <Col sm={12} md={12} lg={4}>
         
         <Card className='rounded-0 shadow-sm mt-4'>
         <Card.Header className='card-title'>
      <Row>
        <Col>
        <div className=' d-flex justify-content-start'>Upcoming Events </div>  
        </Col>
        <Col>
       
        </Col>
      </Row>
     </Card.Header>
     <Card.Body>
     <Card.Text>
     <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     >  <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Seminars and Conferences</div>
         <span className='text-muted'>Monday, May,10, 2021 7 PM - 11PM IST</span>
       </div></Card.Link>
       </ListGroup.Item>
       <hr></hr>
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Product Launch Events</div>
         <span className='text-muted'>Sunday, May,09, 2021 7 PM - 11PM IST</span>
       </div>
       </Card.Link>
       </ListGroup.Item>
       <hr></hr>
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Team-Building Events.</div>
         <span className='text-muted'>Sunday, May,09, 2021 7 PM - 11PM IST</span>
       </div>
       </Card.Link>
       </ListGroup.Item>
       <hr></hr>
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Executive Retreats and IncentivePrograms</div>
         <span className='text-muted'>Sunday, May,09, 2021 7 PM - 11PM IST</span>
       </div>
       </Card.Link>
       </ListGroup.Item>
       <hr></hr>
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Executive Retreats and IncentivePrograms</div>
         <span className='text-muted'>Sunday, May,09, 2021 7 PM - 11PM IST</span>
       </div>
       </Card.Link>
       </ListGroup.Item>
       </Card.Text>
       </Card.Body>
   </Card>
         </Col>
     <Col sm={12} md={12} lg={4}>
     <Card className='rounded-0 shadow-sm mt-4'>
     <Card.Header className='card-title'>
      <Row>
        <Col>
        <div className=' d-flex justify-content-start'>Reminders </div>  
        </Col>
        <Col>
        
        </Col>
      </Row>
      
    
      </Card.Header>
     <Card.Body>
     <Card.Text>
     <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     >  <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">New year's Day</div>
         <span className='text-muted'>Jan 01, 2024</span>
       </div></Card.Link>
       </ListGroup.Item>
       <hr></hr>
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Memorial Day</div>
         <span className='text-muted'>May 29,2024</span>
       </div>
       </Card.Link>
       </ListGroup.Item>
       <hr></hr>
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Independence Day</div>
         <span className='text-muted'>Jul 04, 2024</span>
       </div>
       </Card.Link>
       </ListGroup.Item> 
       <hr></hr>
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Labor Day</div>
         <span className='text-muted'>May 29, 2024</span>
       </div>
       </Card.Link>
       </ListGroup.Item> 
       <hr></hr> 
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Veterans Day  </div>
         <span className='text-muted'>Nov 11, 2024</span>
       </div>
       </Card.Link>
       </ListGroup.Item> 
       </Card.Text>
       </Card.Body>
   </Card>
         </Col>
        
       </Row>
       </Container>
      <Footer/>

      <Modal
        size="md"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm" className='fw-bold'>
          Invite a Friend
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <Form method="POST" onSubmit={handleSubmit} >
             
        <Form.Group className="mb-3" controlId="formBasicEmail">
          
           
       </Form.Group>
       <Form.Group className="mb-3" controlId="formBasicEmail">
            <label>Email *</label>
        <Form.Control type="email" onChange={handleChange} name="useremail" placeholder="Enter Email" />
        <div style={{display:'block'}} className="invalid-feedback small">{error}</div>
        <div style={{display:'block'}} className="alert-success success small">{message}</div>
       </Form.Group>
      
            
      <div className='text-center'>
        <Button type="submit" onClick={validateForm}  style={{backgroundColor:'#2F9DCC'}} className='text-white ml-2'>Send Invite</Button>
      </div>
    </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default Employeedashboard