import React,{useState,useEffect} from 'react'
import {
  Dropdown,
  DropdownButton,
  ButtonGroup,
} from "react-bootstrap";
import Editreferral from './Editreferral';
import Authuser from "../Authuser";
import Modal from 'react-bootstrap/Modal';
import ViewDocument from "./ViewDocument";
function EditDeleteRenderer(props) {
  const {data} = props;
  const [modalshow, setModalshow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedid, setSelectedid] = useState(null);
  const [file, setFile] = useState(false);
  const{http,getUser} = Authuser();
  let userdetails = getUser();
  const[id,setId]=useState('');
  const handleModalShow = (filename) =>{      
        setFile(filename);        
        setModalshow(true);
  }
  const handleShowModal = (id) => {
      setSelectedid(id);
      setShowModal(true);
  };

  const handleCloseModal = () => {
      setSelectedid(null);
      setShowModal(false);
  };
  const handleDelete = () =>{
      http.post(`/employeeselfservice/deletereferral/${userdetails.email}`,{id:selectedid})
      .then((response) => {
          //console.log(response); 
          props.refreshData();
          handleCloseModal();             
      })
      .catch(function (error) {
          // handle error
          console.log(error.response.data.error);
      });
  }
  return (
    <>
      <DropdownButton  as={ButtonGroup} size="sm" autoClose="outside" variant="muted" style={{ maxHeight: "28px" }} title={<span><i class="bi bi-three-dots"></i></span>}>              
        <Dropdown.Item href="#" onClick={()=>handleModalShow(data.file_name)}>View</Dropdown.Item>
        <Dropdown.Item href="#" onClick={()=>handleShowModal(data.id)}>Delete</Dropdown.Item>
      </DropdownButton>
      { /* <Editreferral show={modalshow} id={(modalshow === true && id !='') ? id : ''}  close={() => { setModalshow(false); props.refreshData(); } } /> */ }
      <Modal size="md" aria-labelledby="contained-modal-title-vcenter" show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>                
              <div className="input_fields_wrap">
                  <div className="mb-2 col-md-12">
                      <p style={{ color: '#2D8BB4' }}><strong>Are you sure you want to delete ?</strong></p>
                  </div>
              </div>
          </Modal.Body>
          <Modal.Footer>
              <div className="mb-2 col-md-12 text-center">
                  <button type="button" className="btn btn-secondary ml-2" onClick={handleCloseModal}>No</button> 
                  <button type="submit" onClick={handleDelete} className="btn btn-primary ml-2">Yes</button>
              </div>
          </Modal.Footer>
      </Modal>
      <ViewDocument show={modalshow} filename={file} foldertype="empselfreferralcv" close={() => setModalshow(false)}/>
    </>
  )
}

export default EditDeleteRenderer
