import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Pdf from "../Components/Pdf";
import ViewResponseRenderer from "../Components/ViewResponseRenderer";
import { useState,useMemo } from "react";

function    RequestHistory () {
  
  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState([
    { Subject: "Test",  Message: "Message Here", Attachment: "", DateAndTime: "07/07/2021,10:30 AM ",Action: "", },
    { Subject: "Test",  Message: "Message Here", Attachment: "", DateAndTime: "07/07/2021,10:30 AM ",Action: "", },
    { Subject: "Test",  Message: "Message Here", Attachment: "", DateAndTime: "07/07/2021,10:30 AM ",Action: "", },
    { Subject: "Test",  Message: "Message Here", Attachment: "", DateAndTime: "07/07/2021,10:30 AM ",Action: "", },
    { Subject: "Test",  Message: "Message Here", Attachment: "", DateAndTime: "07/07/2021,10:30 AM ",Action: "", },
    { Subject: "Test",  Message: "Message Here", Attachment: "", DateAndTime: "07/07/2021,10:30 AM ",Action: "", },
    { Subject: "Test",  Message: "Message Here", Attachment: "", DateAndTime: "07/07/2021,10:30 AM ",Action: "", },
    { Subject: "Test",  Message: "Message Here", Attachment: "", DateAndTime: "07/07/2021,10:30 AM ",Action: "", },
    { Subject: "Test",  Message: "Message Here", Attachment: "", DateAndTime: "07/07/2021,10:30 AM ",Action: "", },
    { Subject: "Test",  Message: "Message Here", Attachment: "", DateAndTime: "07/07/2021,10:30 AM ",Action: "", },
    { Subject: "Test",  Message: "Message Here", Attachment: "", DateAndTime: "07/07/2021,10:30 AM ",Action: "", },
    { Subject: "Test",  Message: "Message Here", Attachment: "", DateAndTime: "07/07/2021,10:30 AM ",Action: "", },
    { Subject: "Test",  Message: "Message Here", Attachment: "", DateAndTime: "07/07/2021,10:30 AM ",Action: "", },
    { Subject: "Test",  Message: "Message Here", Attachment: "", DateAndTime: "07/07/2021,10:30 AM ",Action: "", },
    { Subject: "Test",  Message: "Message Here", Attachment: "", DateAndTime: "07/07/2021,10:30 AM ",Action: "", },
    { Subject: "Test",  Message: "Message Here", Attachment: "", DateAndTime: "07/07/2021,10:30 AM ",Action: "", },
    
 

]);
// Column Definitions: Defines the columns to be displayed.
const [colDefs, setColDefs] = useState([
    { field: "Subject"}, 
    { field: "Message"},
     { field: "Attachment",
        cellRenderer: Pdf,
    }, 
    { field: "DateAndTime",  }, 
    { field: "Action",
        cellRenderer: ViewResponseRenderer,
     

    },
       
  

   ]);

   
// ...
const defaultColDef = useMemo(() => {
    return {
        flex: 1, 
        filter: true,
      
        
    }
});
   return (
        <Container fluid>
             <Breadcrumb className='mt-2'>
                <Breadcrumb.Item as={Link} to="/Dashboard">Dashboard</Breadcrumb.Item>
               <Breadcrumb.Item active> Request History</Breadcrumb.Item>
               
            </Breadcrumb>
            <Row className='m-1'>
                <Col sm={4} className='d-flex'>
                    <h6> Request History</h6>
                </Col>
                <Col sm={4} className='pb-2'>
                </Col>
                <Col sm={4} className="d-flex justify-content-end">
              
                </Col>
                <div className="ag-theme-quartz" // applying the grid theme
                style={{ height: 550 }} // the grid will fill the size of the parent container
                >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20]}
                        defaultColDef={defaultColDef}
                       
                    />
                    </div>
           </Row>

             
 </Container>

    );
}

export default  RequestHistory