import React from "react";
import { useState } from 'react';
import { CardLink } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
export default () => {
    const [smShow, setSmShow] = useState(false);
  return (
    <div>
 <CardLink href="#"onClick={() => setSmShow(true)}>View Response</CardLink>

 <Modal
        size="md"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm" >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
          <span className='fw-bold'>View Response</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. </p>
 
        </Modal.Body>
      </Modal>
   
</div> 

    
  )
  
}