import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { useState,useMemo } from "react";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Authuser from '../Authuser'
import Spinner from 'react-bootstrap/Spinner';
function    Timesheets () {
      const now = new Date();
      const current = now.getFullYear();
      console.log(current)
      const previous = current - 1;
      const[loader,setLoader]=useState(false);
      const [values,setValues]=useState({type:'',projecttitle:'',month:'',year:'',workhrs:'',document:''});
      const [errors, setErrors] = useState({type:'',projecttitle:'',month:'',year:'',workhrs:'',document:''});
      const [message,setMessage]= useState();
    const [smShow, setSmShow] = useState(false);
    const { http, getUser } = Authuser();
    let userdetails = getUser();
    const [months,setMonths]=useState(
      {
          'Jan': "01",'Feb': "02",'Mar': "03",'Apr': "04",'May': "05",'Jun': "06",'Jul': "07",'Aug': "08",'Sep': "09",'Oct': "10",'Nov': "11",'Dec': "12",
          "Q1 Jan - Mar" : "01,02,03","Q2 Apr - Jun" : "04,05,06","Q3 Jul - Sep" : "07,08,09","Q4 Oct - Dec" : "10,11,12","Year to Date" : current,
      });
    // Row Data: The data to be displayed.
    console.log("year to date")
    console.log(months[10])
    const [rowData, setRowData] = useState([
              { MonthYear: "Jan",  Quarterly: "Q1 Jan - Mar", HistoricalData: "2022", },
              { MonthYear: "Feb",  Quarterly: "Q2 Apr - Jun", HistoricalData: "2023", },
              { MonthYear: "Mar",  Quarterly: "Q3 Jul - Sep", HistoricalData: "2024", },
              { MonthYear: "Apr",  Quarterly: "Q4 Oct - Dec", HistoricalData: "", },
              { MonthYear: "May",  Quarterly: "Year to Date", HistoricalData: "", },
              { MonthYear: "Jun",  Quarterly: "", HistoricalData: "", },
              { MonthYear: "Jul",  Quarterly: "", HistoricalData: "", },
              { MonthYear: "Aug",  Quarterly: "", HistoricalData: "", },
              { MonthYear: "Sep",  Quarterly: "", HistoricalData: "", },
              { MonthYear: "Oct",  Quarterly: "", HistoricalData: "", },
              { MonthYear: "Nov",  Quarterly: "", HistoricalData: "", },
              { MonthYear: "Dec",  Quarterly: "", HistoricalData: "", },
            ]);
    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
        { field: "MonthYear",
          cellRenderer: (params) => {
          return (
            <a href={`/Timesheetsbyyear/${months[params.value]}`} >
              {params.value} {current}
            </a>
          );
        }}, 
        
        { field: "Quarterly", cellRenderer: (params) => {
          return (
            <a href={`/Timesheetsbyyear/${months[params.value]}`} >
              {params.value}
            </a>
          );
        }}, 
        { field: "HistoricalData",cellRenderer: (params) => {
          return (
            <a href={`/Timesheetsbyyear/${params.value}`} >
              {params.value}
            </a>
          );
        } },
        ]);
    // ...
    const defaultColDef = useMemo(() => {
      return {
        flex: 1, 
        filter: true,
      
        
      }
    });
    
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Subject
        if (!values.type.trim()) {
            newErrors.type = 'Project type is required';
            isValid = false;
        } else {
            newErrors.title = '';
        }
        // Message
        if (!values.projecttitle.trim()) {
            newErrors.projecttitle = 'Project title is required';
            isValid = false;
        } else {
            newErrors.projecttitle = '';
        }
        // File Upload
        if (!values.month.trim()) {
            newErrors.month = 'Month is required';
            isValid = false;
        } else {
            newErrors.month = '';
        }
        if (!values.year.trim()) {
            newErrors.year = 'Year is required';
            isValid = false;
        } else {
            newErrors.year = '';
        }
        if (!values.workhrs.trim()) {
            newErrors.workhrs = 'Work Hours required';
            isValid = false;
        } else {
            newErrors.workhrs = '';
        }
        if (!values.document.trim()) {
            newErrors.document = 'Document is required';
            isValid = false;
        } else {
            newErrors.document = '';
        }
        setErrors(newErrors);
        setMessage('');
        return isValid;
    };
    function handleChange(e){
        setMessage('');
        setValues({...values,[e.target.name]:e.target.value});
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        let type = values.type;
        let projecttitle = values.projecttitle;
        let month = values.month;
        let year = values.year;
        let workhrs = values.workhrs;
        let document = e.target.document.files[0];
        const newErrors = { ...errors };
        
        if (validateForm()) {
          setLoader(true);
          http.post(`/employeeselfservice/uploadtimesheet/${userdetails.email}`,{type:type,projecttitle:projecttitle,month:month,year:year,workhrs:workhrs,file_name:document,userid:userdetails.id}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              console.log(response.data.message);
              setValues({
                type: '',
                projecttitle: '',
                month: '',
                year: '',
                workhrs: '',
                document: '',
              });
              e.target.reset();
              setLoader(false);
              setMessage('Timesheet uploaded successfully!');
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
                newErrors.subject = error.response.data?.error?.subject;
                newErrors.document = error.response.data?.error?.document;
                newErrors.message = error.response.data?.error?.message;
                setErrors(newErrors);
            }
            if(error.response.status==401){
                newErrors.message = error.response.data?.error;
                setErrors(newErrors);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
    return (
      <Container fluid>
        <Breadcrumb className='mt-2'>
          <Breadcrumb.Item as={Link} to="/">Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item active>  Timesheets</Breadcrumb.Item>
        </Breadcrumb>
        <Row className='m-1'>
          <Col sm={4} className='d-flex'><h6> Timesheets</h6></Col>
          <Col sm={4} className='pb-2'></Col>
          <Col sm={4} className="d-flex justify-content-end">
            <Button variant="" onClick={() => setSmShow(true)} className='rounded-0 text-white' style={{backgroundColor:'#2F9DCC'}}>Submit Time Sheets</Button>
          </Col>
          <div className="ag-theme-quartz" // applying the grid theme
                style={{ height: 550 }} // the grid will fill the size of the parent container
          >
            <AgGridReact
                rowData={rowData}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}                
            />
          </div>
        </Row>
        <Modal size="md" show={smShow} onHide={() => setSmShow(false)} aria-labelledby="example-modal-sizes-title-sm">
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm" className='fw-bold'>
            Submit Time Sheets
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form method="POST" onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <label>Project Type *</label>
                <Form.Select name="type" onChange={handleChange}  aria-label="Default select example">
                  <option value="">Select Project Type</option>
                  <option value="Internal">Internal</option>
                  <option value="External">External</option>                
                </Form.Select>
                <div style={{display:'block' }}  className="invalid-feedback small">{errors.type}</div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                    <label>Project / Client name *</label>
                <Form.Control type="text" name="projecttitle" onChange={handleChange} placeholder="Enter Project / Client name" />
                <div style={{display:'block' }}  className="invalid-feedback small">{errors.projecttitle}</div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <label>Month *</label>
                <Form.Select name="month" onChange={handleChange} aria-label="Default select example">
                    <option value="">Select Month</option>
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
      
                </Form.Select>
                <div style={{display:'block' }}  className="invalid-feedback small">{errors.month}</div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <label>Year *</label>
                <Form.Select name="year" required="" onChange={handleChange} aria-label="Default select example">
                  <option value="">Select Year *</option>
                  <option value="2024">2024</option>
                  <option value="2023">2023</option>
                </Form.Select>
                <div style={{display:'block' }}  className="invalid-feedback small">{errors.year}</div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                    <label>Total Working Hours *</label>
                <Form.Control type="text" name="workhrs" maxLength="6" placeholder="Total Working Hours *" onChange={handleChange}  />
                <div style={{display:'block' }}  className="invalid-feedback small">{errors.workhrs}</div>
              </Form.Group>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Submit approved time sheet *</Form.Label>
                <Form.Control type="file" name="document"  accept=".docx,application/pdf" id="formFile" onChange={handleChange} />
                <span>Submit approved time sheet (Word,Pdf,Excel,Jpg,Jpeg,Png files)</span>
                <div style={{display:'block' }}  className="invalid-feedback small">{errors.document}</div>
                <div style={{display:'block' }}  className="alert-success success small">{message}</div>
              </Form.Group>    
              <Col>
              </Col>
              <div className='text-center'>
                {loader === true &&
                  <Spinner animation="border" className='mx-auto' role="status">
                      <span className="visually-hidden">Loading...</span>
                  </Spinner>
                } 
                <Button type="submit" style={{backgroundColor:'#2F9DCC'}} className='text-white ml-2'>Submit</Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>   
      </Container>

    );
}

export default  Timesheets