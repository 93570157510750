import React,{useState,useEffect} from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import Authuser from '../Authuser';
function EmployerProfile() {
    const{http,getUser,setUser}=Authuser();
    let userdetails = getUser();
    let formattedDate;
    const [profiledata,setProfiledata]=useState({});
    const[error,setError]=useState();
    const fetchData = async () => {
        
      http.get(`/employeeselfservice/myprofile/${userdetails.email}`)
      .then((response)=>{
        setUser(response.data.userdata)
        setProfiledata(response.data.userprofile)
        const date = new Date(profiledata.created_at);

        // Format the date as "Dec 29, 2023"
        formattedDate = date.toLocaleDateString('en-US', {
          month: 'short', // "Dec"
          day: 'numeric', // "29"
          year: 'numeric', // "2023"
        });
      }).catch(function (error){
  
      });
  };
    useEffect(() => {
      fetchData();
    },[])
    const handleSave = ({ name, value }) => {
      if(name == 'contactno' || name == 'zipcode'){
        if(name == 'contactno'){
          const numRegex = /^[0-9\b]+$/;
          if (!value.trim()) {
            setError('Phone is required');
          } else if(!numRegex.test(value)){
            setError('Only numbers allowed');
          }else {
            http.post(`/employeeselfservice/updateprofile/${userdetails.email}`,{fieldname:name,updatevalue:value}).then((response) =>{
              //setProfiledata(response.data.userprofile)
              
              fetchData();
            }).catch(function (error){
              console.log(error);
            });
            setError();
          }
        }
        if(name == 'zipcode'){
          const numRegex = /^[0-9\b]+$/;
          if (!value.trim()) {
            setError('Zipcode is required');
          } else if(!numRegex.test(value)){
            setError('Only numbers allowed');
          }else {
            http.post(`/employeeselfservice/updateprofile/${userdetails.email}`,{fieldname:name,updatevalue:value}).then((response) =>{
              //setProfiledata(response.data.userprofile)
              
              fetchData();
            }).catch(function (error){
              console.log(error);
            });
            setError();
          }
        }
      }else{
        http.post(`/employeeselfservice/updateprofile/${userdetails.email}`,{fieldname:name,updatevalue:value}).then((response) =>{
          //setProfiledata(response.data.userprofile)
          
          fetchData();
        }).catch(function (error){
          console.log(error);
        });
      }
      
      
    };
   return (
      <Container fluid>
        <Breadcrumb className='mt-2'>
            <Breadcrumb.Item as={Link} to="/">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Profile</Breadcrumb.Item>
        </Breadcrumb>            
        <Card style={{ width: '60rem' }} className='m-auto rounded-0'>     
          <Card.Body>
            <Card.Text >
              <Row>
                <Col>
                  <div className="box-top">                
                    <div className="profile">                      
                        <div className="profile-img">
                            <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
                        </div>                       
                        <div className="name-user">
                            <strong>Transnational Software Services Inc</strong>
                            <span>Accounting </span>
                        </div>
                    </div>                 
                    <div className="reviews">
                     
                    </div>
                  </div>            
                </Col>           
              </Row>
              <Row>          
                <Col>
                <span style={{display:'block', color: "#bc3f34" }}className="invalid-feedback">{error}</span> 
                  <Table striped bordered hover>    
                    <tbody>
                      <tr>
                        <td className='fw-bold'>Name</td>
                        <td>
                          <EditText name='firstname' onSave={handleSave} defaultValue={userdetails.firstname} editButtonProps={{ style: { marginLeft: '5px', width: 16 } }} showEditButton />                          
                        </td>                        
                      </tr>
                      <tr>
                        <td className='fw-bold'>Member since</td>
                        <td>
                          <EditText name='textbox2' defaultValue={formattedDate} editButtonProps={{ style: { marginLeft: '5px', width: 16 } }} showEditButton readonly/>
                        </td>                        
                      </tr>
                      <tr>
                        <td className='fw-bold'>Employee ID</td>
                        <td>
                          <EditText name='employeeid' defaultValue={profiledata.employeeid} editButtonProps={{ style: { marginLeft: '5px', width: 16 } }} showEditButton readonly/>
                        </td>                        
                      </tr>
                      <tr>
                        <td className='fw-bold'>Email </td>
                        <td>
                          <EditText name='textbox4' defaultValue={userdetails.email} editButtonProps={{ style: { marginLeft: '5px', width: 16 } }} showEditButton readonly />
                        </td>                        
                      </tr>
                      <tr>
                        <td className='fw-bold'>Contact No </td>
                        <td>
                          <EditText name='contactno' maxLength={10} onSave={handleSave} defaultValue={userdetails.contactno} editButtonProps={{ style: { marginLeft: '5px', width: 16 } }} showEditButton />
                        </td>                        
                      </tr>
                      <tr>
                        <td className='fw-bold'>Education</td>
                        <td>  
                          <EditText name='education' onSave={handleSave} defaultValue={profiledata.education} editButtonProps={{ style: { marginLeft: '5px', width: 16 } }} showEditButton />                          
                        </td>                        
                      </tr>
                      <tr>
                        <td className='fw-bold'>Work Experience</td>
                        <td>
                          <EditText name='work_exp' onSave={handleSave} defaultValue={profiledata.work_exp} editButtonProps={{ style: { marginLeft: '5px', width: 16 } }} showEditButton />                          
                        </td>                        
                      </tr>
                      <tr>
                        <td className='fw-bold'>Certifications</td>
                        <td>
                          <EditText name='certifications' onSave={handleSave} defaultValue={profiledata.certifications} editButtonProps={{ style: { marginLeft: '5px', width: 16 } }} showEditButton/>
                        </td>                        
                      </tr>
                   </tbody>
                  </Table>
                </Col>
                <Col>
                  <Table striped bordered hover>    
                    <tbody>
                      <tr>
                        <td className='fw-bold'>Address Line 1</td>
                        <td>
                          <EditText name='addressline1' onSave={handleSave} defaultValue={profiledata.addressline1} editButtonProps={{ style: { marginLeft: '5px', width: 16 } }} showEditButton />
                        </td>                        
                      </tr>
                      <tr>
                      <td className='fw-bold'>Address Line 2</td>
                        <td>
                          <EditText name='addressline2' onSave={handleSave} defaultValue={profiledata.addressline2} editButtonProps={{ style: { marginLeft: '5px', width: 16 } }} showEditButton/>

                        </td>
                        
                      </tr>
                      <tr>
                        <td className='fw-bold'>Town/City</td>
                        <td>
                          <EditText name='city' onSave={handleSave} defaultValue={profiledata.city} editButtonProps={{ style: { marginLeft: '5px', width: 16 } }} showEditButton/>
                        </td>                        
                      </tr>
                      <tr>
                        <td className='fw-bold'>State </td>
                        <td>
                          <EditText name='state' onSave={handleSave} defaultValue={profiledata.state} editButtonProps={{ style: { marginLeft: '5px', width: 16 } }} showEditButton/>
                        </td>                        
                      </tr>
                      <tr>
                        <td className='fw-bold'>Country </td>
                        <td>
                          <EditText name='country' onSave={handleSave} defaultValue={profiledata.country} editButtonProps={{ style: { marginLeft: '5px', width: 16 } }} showEditButton />
                        </td>                        
                      </tr>
                      <tr>
                        <td className='fw-bold'>Zip Code / Pin Code</td>
                        <td>
                          <EditText name='zipcode' onSave={handleSave} defaultValue={profiledata.zipcode} editButtonProps={{ style: { marginLeft: '5px', width: 16 } }} showEditButton />
                        </td>                        
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>    
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>

    );
}

export default EmployerProfile