import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { useState,useMemo } from "react";
import Header from '../Components/Header';
import Footer from '../Components/Footer';

function  AllInvitees () {

  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState([
    { SLNo: "1", Email: "Baldwin@outlook.com",  Status: "Pending", DateAndTime: "May 16, 2022, 13:30 CST",  },
    { SLNo: "2", Email: "Baldwin@outlook.com",  Status: "Joined", DateAndTime: "May 16, 2022, 13:30 CST",  },
    { SLNo: "3", Email: "Baldwin@outlook.com",  Status: "Joined", DateAndTime: "May 16, 2022, 13:30 CST",  },
    { SLNo: "4", Email: "Baldwin@outlook.com",  Status: "Pending", DateAndTime: "May 16, 2022, 13:30 CST",  },
    { SLNo: "5", Email: "Baldwin@outlook.com",  Status: "Pending", DateAndTime: "May 16, 2022, 13:30 CST",  },
    { SLNo: "6", Email: "Baldwin@outlook.com",  Status: "Pending", DateAndTime: "May 16, 2022, 13:30 CST",  },
    { SLNo: "7", Email: "Baldwin@outlook.com",  Status: "Pending", DateAndTime: "May 16, 2022, 13:30 CST",  },
    { SLNo: "8", Email: "Baldwin@outlook.com",  Status: "Pending", DateAndTime: "May 16, 2022, 13:30 CST",  },
    { SLNo: "9", Email: "Baldwin@outlook.com",  Status: "Pending", DateAndTime: "May 16, 2022, 13:30 CST",  },
    { SLNo: "10", Email: "Baldwin@outlook.com",  Status: "Pending", DateAndTime: "May 16, 2022, 13:30 CST",  },
    { SLNo: "11", Email: "Baldwin@outlook.com",  Status: "Pending", DateAndTime: "May 16, 2022, 13:30 CST",  },
    { SLNo: "12", Email: "Baldwin@outlook.com",  Status: "Pending", DateAndTime: "May 16, 2022, 13:30 CST",  },
    { SLNo: "13", Email: "Baldwin@outlook.com",  Status: "Pending", DateAndTime: "May 16, 2022, 13:30 CST",  },
]);
// Column Definitions: Defines the columns to be displayed.
const [colDefs, setColDefs] = useState([
    { field: "SLNo"}, 
    { field: "Email"},
    { field: "Status"},
    { field: "DateAndTime"},
    

   ]);

   
// ...
const defaultColDef = useMemo(() => {
    return {
        flex: 1, 
        filter: true,
      
        
    }
});
   return (
        <Container fluid>
            <Header/>
             <Breadcrumb className='mt-2'>
                <Breadcrumb.Item as={Link} to="/">Dashboard</Breadcrumb.Item>
                 <Breadcrumb.Item active>  All Invitees</Breadcrumb.Item>
               
            </Breadcrumb>
            <Row className='m-1'>
                <Col sm={4} className='d-flex'>
                    <h6>  All Requests</h6>
                </Col>
                <Col sm={4} className='pb-2'>
                </Col>
                <Col sm={4} className="d-flex justify-content-end">
              
                </Col>
                <div className="ag-theme-quartz" // applying the grid theme
                style={{ height: 550 }} // the grid will fill the size of the parent container
                >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20]}
                        defaultColDef={defaultColDef}
                       
                    />
                    </div>
           </Row>

           <Footer/>      
 </Container>

    );
}

export default   AllInvitees