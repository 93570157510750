import React,{ useState,useMemo,useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Pdf from "../Components/Pdf";
import ViewDownload from "../Components/ViewDownload";
import Spinner from 'react-bootstrap/Spinner';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Authuser from '../Authuser';
function    Documents () {
    const [smShow, setSmShow] = useState(false);
    const[loader,setLoader]=useState(false);
  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState([
    
]);
// Column Definitions: Defines the columns to be displayed.
const [colDefs, setColDefs] = useState([
    { headerName:'Document Title',field: "title"}, 
    
     { headerName:'Document', field: "file_name",
        cellRenderer: Pdf,
    }, 
    { headerName: "DocumentDate",field:"created_at"  }, 
    { field: "Action",
        cellRenderer: ViewDownload,
        cellRendererParams: {
            folderName: 'empselfdocncertificates' // Pass folder name or other data as custom params
        }
     

    },
       
  

   ]);

   
// ...
const defaultColDef = useMemo(() => {
    return {
        flex: 1, 
        filter: true,
      
        
    }
});
const { http, getUser } = Authuser();
    let userdetails = getUser();
    const [values,setValues]=useState({title:'',document:''});
    const [errors, setErrors] = useState({title: '',document: '',message:'' });
    const [message,setMessage]= useState();
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Validate email
        if (!values.title.trim()) {
            newErrors.title = 'Title is required';
            isValid = false;
        } else {
            newErrors.title = '';
        }
        // Validate password
        if (!values.document.trim()) {
            newErrors.document = 'Document is required';
            isValid = false;
        } else {
            newErrors.password = '';
        }
        setMessage('');
        setErrors(newErrors);
        return isValid;
    };
      
    function handleChange(e){
        setMessage('');
        setValues({...values,[e.target.name]:e.target.value})
    }
    function fetchItems(){
        var url = `/employeeselfservice/documents/${userdetails.email}`;
        http.get(url)
        .then((response) => {
            //console.log(response.data);
            setRowData(response.data.mydocs);
            setMessage('');
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
            
        });
    }
    useEffect(() => {
        fetchItems();        
    }, []);
    const handleSubmit = async(e) =>{
        e.preventDefault();
        //validation(values);
        let title = values.title;
        let document = e.target.document.files[0];
        let documenttype = 'DOCS';
        const newErrors = { ...errors };
        
        if (validateForm()) {
            setLoader(true);
            http.post(`/employeeselfservice/uploaddocument/${userdetails.email}`,{title:title,file:document,document_type:documenttype}).then((response)=>{
                //setToken(response.data.userdetail,response.data.token,usertype);
                //console.log(response.status);
                console.log(response.data.message);
                setValues({title: '',document: ''});
                    e.target.reset();
                    fetchItems();
                    setLoader(false);
                setMessage('FIle uploaded successfully!')
                //setLoader(false);
                fetchItems();
                
            }).catch(function (error) {
                // handle error
                //console.log(error.response.data.error);
                if(error.response.status==403){
                newErrors.title = error.response.data?.error?.title;
                newErrors.document = error.response.data?.error?.file;
                setErrors(newErrors);
                }
                if(error.response.status==401){
                newErrors.message = error.response.data?.error;
                setErrors(newErrors);
                }
                //setsubmitted(false)
            })           
          
        } else {
          // Form is not valid, display error messages
    
        }
    }
   return (
        <Container fluid>
             <Breadcrumb className='mt-2'>
                <Breadcrumb.Item as={Link} to="/Admindashboard">Dashboard</Breadcrumb.Item>
               <Breadcrumb.Item active> My Documents</Breadcrumb.Item>
               
            </Breadcrumb>
            <Row className='m-1'>
                <Col sm={4} className='d-flex'>
                    <h6> Documents</h6>
                </Col>
                <Col sm={4} className='pb-2'>
                </Col>
                <Col sm={4} className="d-flex justify-content-end">
                <Button variant="" onClick={() => setSmShow(true)} className='rounded-0 text-white' style={{backgroundColor:'#2F9DCC'}}>Upload Document</Button>
                </Col>
                <div className="ag-theme-quartz" // applying the grid theme
                style={{ height: 550 }} // the grid will fill the size of the parent container
                >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20]}
                        defaultColDef={defaultColDef}
                       
                    />
                    </div>
           </Row>

           <Modal size="md" show={smShow} onHide={() => setSmShow(false)} aria-labelledby="example-modal-sizes-title-sm">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm" className='fw-bold'>
                        Upload Document
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form method="POST" onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                <label>Document Name *</label>
                            <Form.Control type="text" name="title" onChange={handleChange} placeholder="Enter Document Name" />
                            <div style={{display:'block' }} className="invalid-feedback small">{errors.title}</div>
                        </Form.Group>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Upload Document *</Form.Label>
                            <Form.Control type="file" name="document" onChange={handleChange} />
                            <div style={{display:'block' }}  className="invalid-feedback small">{errors.document}</div>
                            <div style={{display:'block' }} className="invalid-feedback small">{errors.message}</div>
                        </Form.Group>    
                        <div style={{display:'block' }}  className="alert-success small">{message}</div>
                            <Col>
                            </Col>
                        
                        <div className='text-center'>
                        {loader === true &&
                            <Spinner animation="border" className='mx-auto' role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        } 
                            <Button type="submit" onClick={validateForm}  style={{backgroundColor:'#2F9DCC'}} className='text-white ml-2'>Submit</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>    
        </Container>

    );
}

export default  Documents