import React,{ useState,useMemo,useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Authuser from '../Authuser';
function    ViewAllReportingManagers () {
    const [smShow, setSmShow] = useState(false);
  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState([
    
  
]);
const [reportData, setReportData] = useState([
    {"firstname":"vijay","lastname":"vijay","email":"vijay.k@tnsservices.com","mobileno":"+1-940-303-9299"}
  
]);
// Column Definitions: Defines the columns to be displayed.
const [colDefs, setColDefs] = useState([
    { headerName:"First Name",field: "firstname"},  
    { headerName:"Last Name",field: "lastname", },       
    { headerName:"Email",field: "email" },
    { headerName:"Mobile",field: "mobileno" },
   ]);

   
// ...
const defaultColDef = useMemo(() => {
    return {
        flex: 1, 
        filter: true,
      
        
    }
});
const [values,setValues]=useState({clientname:'',firstname:'',lastname:'',mobileno:'',email:''});
    const [errors, setErrors] = useState({clientname:'',firstname:'',lastname:'',mobileno:'',email:''});
    const [message,setMessage]= useState();
    const [clientmanager, setClientmanager] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { http, getUser } = Authuser();
    let userdetails = getUser();
    let usertype = 'applicant';
    let dashboard=`/${usertype}`;
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const alphaRegex = /^[A-Za-z]+$/;
        const numRegex = /^[0-9\b]+$/;
        // Subject
        if (!values.clientname.trim()) {
            newErrors.clientname = 'Client name is required';
            isValid = false;
        } else if(!alphaRegex.test(values.clientname)){
            newErrors.clientname='Only Characters allowed';
            isValid = false;
        }else if(values.clientname.length < 3){
            newErrors.clientname='Atleast 3 Characters allowed';
            isValid = false;
        }else {
            newErrors.clientname = '';
        }
        // Message
        if (!values.firstname.trim()) {
            newErrors.firstname = 'First name is required';
            isValid = false;
        } else if(!alphaRegex.test(values.firstname)){
            newErrors.firstname = 'Only Characters allowed';
            isValid = false;
        }else if(values.firstname.length < 3){
            newErrors.firstname='Atleast 3 Characters allowed';
            isValid = false;
        }else {
            newErrors.firstname = '';
        }
        // File Upload
        if (!values.lastname.trim()) {
            newErrors.lastname = 'Last name is required';
            isValid = false;
        } else if(!alphaRegex.test(values.lastname)){
            newErrors.lastname = 'Only Characters allowed';
            isValid = false;
        }else if(values.lastname.length < 3){
            newErrors.lastname='Atleast 3 Characters allowed';
            isValid = false;
        }else {
            newErrors.lastname = '';
        }
        if (!values.mobileno.trim()) {
            newErrors.mobileno = 'Mobile no is required';
            isValid = false;
        }else if(!numRegex.test(values.mobileno)){
            newErrors.mobileno='Only numbers allowed';
            isValid = false;
        }else if (values.mobileno.length < 10) {
            newErrors.mobileno='must be at least 10 digits';
            isValid = false;
        } else {
            newErrors.mobileno = '';
        }
        if (!values.email.trim()) {
            newErrors.email = 'Email is required';
            isValid = false;
        } else  if(!emailRegex.test(values.email)){
            newErrors.email = 'Email not valid';
            isValid = false;
        }else {
            newErrors.email = '';
        }
        setErrors(newErrors);
        setMessage('');
        return isValid;
    };
    function handleChange(e){
        setMessage('');
        setValues({...values,[e.target.name]:e.target.value});
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        let clientname = values.clientname;
        let firstname = values.firstname;
        let lastname = values.lastname;
        let mobileno = values.mobileno;
        let email = values.email;
        let message = '';
        const newErrors = { ...errors };
        
        if (validateForm()) {
          http.post(`/employeeselfservice/addclientmanager/${userdetails.email}`,{clientname:clientname,firstname:firstname,lastname:lastname,mobileno:mobileno,email:email,userid:userdetails.id}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              console.log(response.data.message);
              setValues({
                clientname: '',
                firstname: '',
                lastname: '',
                telephone: '',
                mobileno: '',
                email: '',
                message: '',
              });
              e.target.reset();
              setMessage('Client manager added successfully!');
              fetchItems();
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
                newErrors.clientname = error.response.data?.error?.clientname;
                newErrors.firstname = error.response.data?.error?.firstname;
                newErrors.lastname = error.response.data?.error?.lastname;
                newErrors.telephone = error.response.data?.error?.telephone;
                newErrors.mobileno = error.response.data?.error?.mobileno;
                newErrors.email = error.response.data?.error?.email;
                setErrors(newErrors);
            }
            if(error.response.status==401){
                newErrors.message = error.response.data?.error;
                setErrors(newErrors);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
    const fetchItems = async () => {
        http.get(`/employeeselfservice/reportingmanager/${userdetails.email}`)
        .then((response) => {
        //console.log(response.data);
        setClientmanager(response.data.clientmanagers);
        })
        .catch(function (error) {
        // handle error
        console.log(error.response.data.error);
        });
      };
    useEffect(() => {
        fetchItems();
    }, []);
   return (
        <Container fluid>
            <Breadcrumb className='mt-2'>
                <Breadcrumb.Item as={Link} to="/">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item active> Transnational Reporting Manager</Breadcrumb.Item>
            </Breadcrumb>
            <Row className='m-1'>
                <Col sm={4} className='d-flex'><h6>Transnational Reporting Managers</h6></Col>
                <Col sm={4} className='pb-2'></Col>
                <Col sm={4} className="d-flex justify-content-end"></Col>
                <div className="ag-theme-quartz" // applying the grid theme
                    style={{ height: 250 }} // the grid will fill the size of the parent container
                >
                    <AgGridReact
                        rowData={reportData}
                        columnDefs={colDefs}
                        defaultColDef={defaultColDef}                       
                    />
                    <Row className='mt-4'>
                        <Col sm={4} className='d-flex'><h6>Client Reporting Manager </h6></Col>
                        <Col sm={4} className='pb-2'></Col>
                        <Col sm={4} className="d-flex justify-content-end">
                            <Button variant="" onClick={() => setSmShow(true)} className='rounded-0 text-white' style={{backgroundColor:'#2F9DCC'}}>Add New</Button>
                        </Col>      
                    </Row>
                    <AgGridReact
                        rowData={clientmanager}
                        columnDefs={colDefs}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20]}
                        defaultColDef={defaultColDef}
                       
                    />
                </div>
           </Row>

           <Modal size="md" show={smShow} onHide={() => setSmShow(false)} aria-labelledby="example-modal-sizes-title-sm">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm" className='fw-bold'>
                        Client Reporting Manager
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form method="POST" onSubmit={handleSubmit}>
                        <Form.Group className="mb-3"  controlId="formBasicEmail">
                            <label>Client Name *</label>
                            <Form.Control type="text" name="clientname"  onChange={handleChange} placeholder="Enter Client Name" />
                            <div style={{display:'block' }} className="invalid-feedback small">{errors.clientname}</div>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <label>First Name *</label>
                            <Form.Control type="text" name="firstname" maxLength={30}  onChange={handleChange} placeholder="Reporting Manager First Name" />
                            <div style={{display:'block' }} className="invalid-feedback small">{errors.firstname}</div>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                <label>Last Name *</label>
                            <Form.Control type="text"  name="lastname" maxLength={30}  onChange={handleChange} placeholder="Reporting Manager Last Name" />
                            <div style={{display:'block' }} className="invalid-feedback small">{errors.lastname}</div>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                <label>Email *</label>
                            <Form.Control type="email" name="email" onChange={handleChange} placeholder="Reporting Manager Email" />
                            <div style={{display:'block' }} className="invalid-feedback small">{errors.email}</div>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                <label>Mobile No *</label>
                            <Form.Control type="text" name="mobileno" maxLength="10" onChange={handleChange} placeholder="Reporting Manager Telephone No" />
                            <div style={{display:'block' }} className="invalid-feedback small">{errors.mobileno}</div>
                            <div style={{display:'block' }} className="alert-success small">{message}</div>
                        </Form.Group>
                        <Col>
                        </Col>
                        <div className='text-center'>
                            <Button type="submit" style={{backgroundColor:'#2F9DCC'}} onClick={validateForm} className='text-white ml-2'>Submit</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal> 
        </Container>

    );
}

export default  ViewAllReportingManagers