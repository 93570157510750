import React from 'react'
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Userico from '../Assets/User_ico.svg';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
function Notificatons() {
  return (
    <Container fluid>
      <Header/>
    <Container className='mt-3'>
        <Row>
        <Card className='rounded-0'>
      <Card.Body>
      <Row className='border-bottom p-2'>
      <Col xs sm={12} md={12} lg={1}><img src={Userico} alt='User' className='img-fluid'/></Col>
        <Col sm={12} md={12} lg={9}>
        <span className='roboto-bold'>Jennifer Morris </span><br/>
        <span className='small'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
        </Col>
        <Col xs sm={12} md={12} lg={2} > 
         <p className='small d-flex justify-content-end'>20-Oct-2021,10:00 AM</p>
        <Dropdown className='d-flex justify-content-end'>
      <Dropdown.Toggle className='joboptions'>
      <i class="bi bi-three-dots"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1" className="me-2">Delete</Dropdown.Item>
        <Dropdown.Item href="#/action-2"  className="me-2">Block</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Unblock</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
        </Col>
</Row>
<Row className='border-bottom p-2'>
      <Col xs sm={12} md={12} lg={1}><img src={Userico} alt='User' className='img-fluid'/></Col>
        <Col sm={12} md={12} lg={9}>
        <span className='roboto-bold'>Jennifer Morris </span><br/>
        <span className='small'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
        </Col>
        <Col xs sm={12} md={12} lg={2} > 
         <p className='small d-flex justify-content-end'>20-Oct-2021,10:00 AM</p>
        <Dropdown className='d-flex justify-content-end'>
      <Dropdown.Toggle className='joboptions'>
      <i class="bi bi-three-dots"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1" className="me-2">Delete</Dropdown.Item>
        <Dropdown.Item href="#/action-2"  className="me-2">Block</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Unblock</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
        </Col>
</Row>
<Row className='border-bottom p-2'>
      <Col xs sm={12} md={12} lg={1}><img src={Userico} alt='User' className='img-fluid'/></Col>
        <Col sm={12} md={12} lg={9}>
        <span className='roboto-bold'>Jennifer Morris </span><br/>
        <span className='small'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
        </Col>
        <Col xs sm={12} md={12} lg={2} > 
         <p className='small d-flex justify-content-end'>20-Oct-2021,10:00 AM</p>
        <Dropdown className='d-flex justify-content-end'>
      <Dropdown.Toggle className='joboptions'>
      <i class="bi bi-three-dots"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1" className="me-2">Delete</Dropdown.Item>
        <Dropdown.Item href="#/action-2"  className="me-2">Block</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Unblock</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
        </Col>
</Row>
<Row className='border-bottom p-2'>
      <Col xs sm={12} md={12} lg={1}><img src={Userico} alt='User' className='img-fluid'/></Col>
        <Col sm={12} md={12} lg={9}>
        <span className='roboto-bold'>Jennifer Morris </span><br/>
        <span className='small'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
        </Col>
        <Col xs sm={12} md={12} lg={2} > 
         <p className='small d-flex justify-content-end'>20-Oct-2021,10:00 AM</p>
        <Dropdown className='d-flex justify-content-end'>
      <Dropdown.Toggle className='joboptions'>
      <i class="bi bi-three-dots"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1" className="me-2">Delete</Dropdown.Item>
        <Dropdown.Item href="#/action-2"  className="me-2">Block</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Unblock</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
        </Col>
</Row>

<Row className='border-bottom p-2'>
      <Col xs sm={12} md={12} lg={1}><img src={Userico} alt='User' className='img-fluid'/></Col>
        <Col sm={12} md={12} lg={9}>
        <span className='roboto-bold'>Jennifer Morris </span><br/>
        <span className='small'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
        </Col>
        <Col xs sm={12} md={12} lg={2} > 
         <p className='small d-flex justify-content-end'>20-Oct-2021,10:00 AM</p>
        <Dropdown className='d-flex justify-content-end'>
      <Dropdown.Toggle className='joboptions'>
      <i class="bi bi-three-dots"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1" className="me-2">Delete</Dropdown.Item>
        <Dropdown.Item href="#/action-2"  className="me-2">Block</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Unblock</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
        </Col>
</Row>
<Row className='border-bottom p-2'>
      <Col xs sm={12} md={12} lg={1}><img src={Userico} alt='User' className='img-fluid'/></Col>
        <Col sm={12} md={12} lg={9}>
        <span className='roboto-bold'>Jennifer Morris </span><br/>
        <span className='small'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
        </Col>
        <Col xs sm={12} md={12} lg={2} > 
         <p className='small d-flex justify-content-end'>20-Oct-2021,10:00 AM</p>
        <Dropdown className='d-flex justify-content-end'>
      <Dropdown.Toggle className='joboptions'>
      <i class="bi bi-three-dots"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1" className="me-2">Delete</Dropdown.Item>
        <Dropdown.Item href="#/action-2"  className="me-2">Block</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Unblock</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
        </Col>
</Row>

<Row className='border-bottom p-2'>
      <Col xs sm={12} md={12} lg={1}><img src={Userico} alt='User' className='img-fluid'/></Col>
        <Col sm={12} md={12} lg={9}>
        <span className='roboto-bold'>Jennifer Morris </span><br/>
        <span className='small'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
        </Col>
        <Col xs sm={12} md={12} lg={2} > 
         <p className='small d-flex justify-content-end'>20-Oct-2021,10:00 AM</p>
        <Dropdown className='d-flex justify-content-end'>
      <Dropdown.Toggle className='joboptions'>
      <i class="bi bi-three-dots"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1" className="me-2">Delete</Dropdown.Item>
        <Dropdown.Item href="#/action-2"  className="me-2">Block</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Unblock</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
        </Col>
</Row>

<Row className='border-bottom p-2'>
      <Col xs sm={12} md={12} lg={1}><img src={Userico} alt='User' className='img-fluid'/></Col>
        <Col sm={12} md={12} lg={9}>
        <span className='roboto-bold'>Jennifer Morris </span><br/>
        <span className='small'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
        </Col>
        <Col xs sm={12} md={12} lg={2} > 
         <p className='small d-flex justify-content-end'>20-Oct-2021,10:00 AM</p>
        <Dropdown className='d-flex justify-content-end'>
      <Dropdown.Toggle className='joboptions'>
      <i class="bi bi-three-dots"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1" className="me-2">Delete</Dropdown.Item>
        <Dropdown.Item href="#/action-2"  className="me-2">Block</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Unblock</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
        </Col>
</Row>
<Row className='border-bottom p-2'>
      <Col xs sm={12} md={12} lg={1}><img src={Userico} alt='User' className='img-fluid'/></Col>
        <Col sm={12} md={12} lg={9}>
        <span className='roboto-bold'>Jennifer Morris </span><br/>
        <span className='small'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
        </Col>
        <Col xs sm={12} md={12} lg={2} > 
         <p className='small d-flex justify-content-end'>20-Oct-2021,10:00 AM</p>
        <Dropdown className='d-flex justify-content-end'>
      <Dropdown.Toggle className='joboptions'>
      <i class="bi bi-three-dots"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1" className="me-2">Delete</Dropdown.Item>
        <Dropdown.Item href="#/action-2"  className="me-2">Block</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Unblock</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
        </Col>
</Row>
<Row className='border-bottom p-2'>
      <Col xs sm={12} md={12} lg={1}><img src={Userico} alt='User' className='img-fluid'/></Col>
        <Col sm={12} md={12} lg={9}>
        <span className='roboto-bold'>Jennifer Morris </span><br/>
        <span className='small'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
        </Col>
        <Col xs sm={12} md={12} lg={2} > 
         <p className='small d-flex justify-content-end'>20-Oct-2021,10:00 AM</p>
        <Dropdown className='d-flex justify-content-end'>
      <Dropdown.Toggle className='joboptions'>
      <i class="bi bi-three-dots"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1" className="me-2">Delete</Dropdown.Item>
        <Dropdown.Item href="#/action-2"  className="me-2">Block</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Unblock</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
        </Col>
</Row>
<Row className='border-bottom p-2'>
      <Col xs sm={12} md={12} lg={1}><img src={Userico} alt='User' className='img-fluid'/></Col>
        <Col sm={12} md={12} lg={9}>
        <span className='roboto-bold'>Jennifer Morris </span><br/>
        <span className='small'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
        </Col>
        <Col xs sm={12} md={12} lg={2} > 
         <p className='small d-flex justify-content-end'>20-Oct-2021,10:00 AM</p>
        <Dropdown className='d-flex justify-content-end'>
      <Dropdown.Toggle className='joboptions'>
      <i class="bi bi-three-dots"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1" className="me-2">Delete</Dropdown.Item>
        <Dropdown.Item href="#/action-2"  className="me-2">Block</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Unblock</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
        </Col>
</Row>
      </Card.Body>
    </Card>

        </Row>
</Container>

<Footer/>

    </Container>
);
}
  
    export default Notificatons