import React,{ useState,useEffect,useMemo } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link,useNavigate } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Authuser from '../Authuser';
import Spinner from 'react-bootstrap/Spinner';
const ReferralPolicy = () => {
    const [smShow, setSmShow] = useState(false);
    const[loader,setLoader]=useState(false);
    const date = new Date().toDateString();
    const{http,getUser}=Authuser();
    let userdetails = getUser();
    let navigate = useNavigate(); 
    const routeChange = () =>{ 
      let path = `newPath`; 
      navigate('/MyReferrals');
    }
    

  const [values,setValues]=useState({firstname:'',lastname:'',contactno:'',email:'',document:''});
  const [errors, setErrors] = useState({firstname:'',lastname:'',contactno:'',email:'',document:'',message:''});
  const [message,setMessage]= useState();
  const validateForm = () => {
      let isValid = true;
      const newErrors = { ...errors };
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const alphaRegex = /^[A-Za-z]+$/;
      const numRegex = /^[0-9\b]+$/;
      // Subject
      // Message
      if (!values.firstname.trim()) {
          newErrors.firstname = 'First name is required';
          isValid = false;
      }else if(!alphaRegex.test(values.firstname)){
        newErrors.firstname='Only Characters allowed';
        isValid = false;
      }else {
          newErrors.firstname = '';
      }
      // File Upload
      if (!values.lastname.trim()) {
          newErrors.lastname = 'Last name is required';
          isValid = false;
      } else if(!alphaRegex.test(values.lastname)){
        newErrors.lastname='Only Characters allowed';
        isValid = false;
      }else {
          newErrors.lastname = '';
      }
      if (!values.contactno.trim()) {
          newErrors.contactno = 'Mobile no is required';
          isValid = false;
      } else if(!numRegex.test(values.contactno)){
            newErrors.contactno='Only numbers allowed';
            isValid = false;
      }else {
          newErrors.contactno = '';
      }
      if (!values.email.trim()) {
          newErrors.email = 'Email is required';
          isValid = false;
      } else {
          newErrors.email = '';
      }
      if (!values.document.trim()) {
          newErrors.document = 'Document is required';
          isValid = false;
      } else {
          newErrors.document = '';
      }
      setErrors(newErrors);
      setMessage('');
      return isValid;
  };
    function handleChange(e){
        setMessage('');
        setValues({...values,[e.target.name]:e.target.value});
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        let firstname = values.firstname;
        let lastname = values.lastname;
        let contactno = values.contactno;
        let email = values.email;
        let document = e.target.document.files[0];
        let message = '';
        const newErrors = { ...errors };
        
        if (validateForm()) {
          setLoader(true);
          http.post(`/employeeselfservice/addreferral/${userdetails.email}`,{firstname:firstname,lastname:lastname,contactno:contactno,email:email,file:document,userid:userdetails.id}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              console.log(response.data.message);
              setValues({
                firstname: '',
                lastname: '',
                contactno: '',
                email: '',
                document: ''
              });
              setLoader(false);
              setMessage('My referral added successfully!');
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
                newErrors.firstname = error.response.data?.error?.firstname;
                newErrors.lastname = error.response.data?.error?.lastname;
                newErrors.contactno = error.response.data?.error?.contactno;
                newErrors.email = error.response.data?.error?.email;
                newErrors.document = error.response.data?.error?.file;
                setErrors(newErrors);
            }
            if(error.response.status==413){
                newErrors.message = error.response.data?.error?.message;
                setErrors(newErrors);
            }
            if(error.response.status==401){
                newErrors.message = error.response.data?.error;
                setErrors(newErrors);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
    return (
        <Container fluid>
          <Breadcrumb className='mt-2'>
              <Breadcrumb.Item as={Link} to="/">Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item active> Employee Referral Policy  </Breadcrumb.Item>                
          </Breadcrumb>           
          <Container className='mt-3'>
            <Row>
              <Col sm={4} className='d-flex'></Col>
              <Col sm={4} className='pb-2'></Col>
              <Col sm={4} className="d-flex justify-content-end">
               <Button variant="" onClick={() => setSmShow(true)}  className='rounded-0 text-white' style={{backgroundColor:'#6D9C41'}}>Add Referrals </Button>
               <Button variant="" onClick={routeChange}  className='rounded-0 text-white ml-2' style={{backgroundColor:'#2F9DCC'}}>My Referrals </Button>
              </Col>
            </Row>
            <Container className='bg-white p-4 border'>             
              <Row>
                <h6 className='fw-bold text-center'>Employee Referral Policy</h6>
                <hr></hr>
                <p className='fw-bold'>Introduction</p>  
                <p>“Transnational Software Services Inc” Employee Referral Program is a recruiting tool designed to encourage “Transnational Software Services Inc”employees to refer qualified candidates to fill open positions at “Transnational Software Services Inc”.
                </p>  
                <p className='fw-bold'>Eligibility</p>  
                <p>Current employees of “Transnational Software Services Inc” are eligible to make referrals through this program. In case of multiple referrals, the first employee to submit the referral will be recognized as the referring employee. The candidate who is referred to the open job position should not be a current employee of the company.
                </p> 
                <p className='fw-bold'>Incentives Offered </p>  
                <p>If the new employee, who was hired through employee referral program, stays with the company for at least 90_days or more, then the referring employee receives a special bonus in the amount of $1000. There is no limit to the number of referrals an employee can make or the number of referral bonuses an employee may receive.
                </p> 
                <p className='fw-bold'>Referral Procedure  </p>  
                <p>Candidates can be referred to any open position by contacting the HR directly. If the candidate referred by the employee is offered and accepts the position, HR will contact the employee who made the referral directly.  Upon meeting the requirements the candidate must apply with the HR Department for the referral fees by providing the candidate name.
                </p>    
              </Row>
            </Container>
            <p>Note : Please mail your resumes to : 8951 Cypress Waters Blvd, Ste #160 Coppell, TX - 75019. Tel: 575-680-2535 | Fax: 786-664-6508.             </p>
            <p className='d-flex justify-content-end'>Last updated on : {date}</p>
          </Container>
          <Modal
        size="md"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm" className='fw-bold'>
          Add Employee Referral
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form method="POST" onSubmit={handleSubmit}>
             
       
          <Form.Group className="mb-3" controlId="formBasicEmail">
                <label>First Name *</label>
            <Form.Control type="text" name="firstname" onChange={handleChange} placeholder="Enter First Name" />
            <div style={{display:'block', color: "#bc3f34" }} className="invalid-feedback small">{errors.firstname}</div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
                <label>Last Name *</label>
            <Form.Control type="text" name="lastname" onChange={handleChange} placeholder="Enter Last Name" />
            <div style={{display:'block', color: "#bc3f34" }} className="invalid-feedback small">{errors.lastname}</div> 
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
                <label>Email *</label>
            <Form.Control type="email" name="email" onChange={handleChange} placeholder="Enter Email" />
            <div style={{display:'block', color: "#bc3f34" }} className="invalid-feedback small">{errors.email}</div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
                <label>Contact No *</label>
            <Form.Control type="text" name="contactno" minLength={10} maxLength={10} onChange={handleChange} placeholder="Enter Contact No" />
            <div style={{display:'block', color: "#bc3f34" }} className="invalid-feedback small">{errors.contactno}</div>
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Upload Document *</Form.Label>
            <Form.Control type="file" name="document"  accept=".docx,application/pdf" id="formFile" onChange={handleChange}  />
            <div style={{display:'block', color: "#bc3f34" }} className="invalid-feedback small">{errors.document}</div>
            <div style={{display:'block', color: "#bc3f34" }} className="invalid-feedback small">{errors.message}</div>   
            <div style={{display:'block', color: "#1cc88a" }} className="alert-success small">{message}</div>
          </Form.Group>    
            <Col>
            </Col>
          
          <div className='text-center'>
            {loader === true &&
                <Spinner animation="border" className='mx-auto' role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            } 
            <Button type="submit" onClick={validateForm} style={{backgroundColor:'#2F9DCC'}} className='text-white ml-2'>Submit</Button>
          </div>
        </Form>
        </Modal.Body>
      </Modal> 
    </Container>
    );
}

export default ReferralPolicy