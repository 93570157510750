import React from 'react'
import {Routes,Route} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../Assets/EmploymentExchange_Logo.svg';
import Form from 'react-bootstrap/Form';
import Networking from '../Assets/Networking.svg';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import support from '../Assets/Support_ico.svg';
import Authuser from '../Authuser';
import Dashboard from '../Pages/Dashboard';
import AllInvitees from '../Pages/AllInvitees';
import Feedback from '../Pages/Feedback';
import SupportCenter from '../Pages/SupportCenter';
import NewEmployeeDocumentation from '../Pages/NewEmployeeDocumentation';
import ViewAllReportingManagers from '../Pages/ViewAllReportingManagers';
import PerformanceReviewForm from '../Pages/PerformanceReviewForm';
import ViewAllPerformanceReviewForms from '../Pages/ViewAllPerformanceReviewForms';
import Timesheets from '../Pages/Timesheets';
import Timesheetsbyyear from '../Pages/Timesheetsbyyear';
import MyDocuments from '../Pages/MyDocuments';
import MyRequest from '../Pages/MyRequest';
import RequestHistory from '../Pages/RequestHistory';
import PayrollCalendar from '../Pages/PayrollCalendar';
import PaycheckLoginInfo from '../Pages/PaycheckLoginInfo';
import H1BDocumentation from '../Pages/H1BDocumentation';
import InsuranceVendorInfo from '../Pages/InsuranceVendorInfo';
import ReferralPolicy from '../Pages/ReferralPolicy';
import MyReferrals from '../Pages/MyReferrals';
import Suggestions from '../Pages/Suggestions';
import Notifications from '../Pages/Notifications';
import Messages from '../Pages/Messages';
import Changepassword from '../Pages/Changepassword';
import Myprofile from '../Pages/Myprofile';

function Header() {
  const {token,logout,getUser}= Authuser();
  let userdetails = getUser();
  const logoutUser  = () =>{
    if(token !== undefined){
      logout();
    }
  }
  return (
    <div > 
      <nav class="navbar navbar-expand-lg" style={{backgroundColor:"#ffffff"}}>
        <div class="container-fluid">
        <Navbar.Brand  as={Link} to="/"><img src={logo} alt='Employment Exchange' className='img-fluid logo' style={{width:320}} /></Navbar.Brand>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <ul class="navbar-nav ms-md-auto gap-2 list-group-horizontal">           
            <li class="nav-item dropdown rounded ">
              <Nav.Link  class="nav-link dropdown-toggle " href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" > <i class="bi bi-grid-3x3-gap-fill" style={{fontSize:'14px'}}></i> <span style={{fontSize:'14px'}}>Services</span></Nav.Link >
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <Dropdown.Item as={Link} to="/Dashboard">Home</Dropdown.Item>
                <Dropdown.Item as={Link} to="/NewEmployeeDocumentation">New Employee Documentation</Dropdown.Item>
                <Dropdown.Item as={Link} to="/ViewAllReportingManagers">My Reporting Manager</Dropdown.Item>
                <Dropdown.Item as={Link} to="/ViewAllPerformanceReviewForms">Performance Review Overdue </Dropdown.Item>
                <Dropdown.Item as={Link} to="/Timesheets">Timesheets</Dropdown.Item>
                <Dropdown.Item as={Link} to="/MyDocuments">My Documents</Dropdown.Item>
                <Dropdown.Item as={Link} to="/MyRequest">My Request</Dropdown.Item>
                <Dropdown.Item as={Link} to="/PayrollCalendar">Payroll Calendar</Dropdown.Item>
                <Dropdown.Item as={Link} to="/PaycheckLoginInfo">Paychex Login Info</Dropdown.Item>
                <Dropdown.Item as={Link} to="/H1BDocumentation">H1b Documentation</Dropdown.Item>
                <Dropdown.Item as={Link} to="/InsuranceVendorInfo">Insurance Vendor Info</Dropdown.Item>
                <Dropdown.Item as={Link} to="/ReferralPolicy">Employee Referral Policy</Dropdown.Item>
                <Dropdown.Item as={Link} to="/MyReferrals">My Referrals</Dropdown.Item>
              </ul>
            </li>                    
          </ul>
          <div class="collapse navbar-collapse" id="navbarNav">        
          <Form className="me-auto my-2 my-lg-0">
            <Form.Control type="search" placeholder="Search" className="me-2 search rounded-0" aria-label="Search" />
          </Form>
          <ul class="navbar-nav ms-md-auto gap-2 list-group-horizontal">              
              <li class="nav-item rounded">
              <Nav.Link as={Link} to="/Connections" className='text-center roboto-light'><img src={Networking} alt='Networking' className='img-fluid'/> <br/>My Network</Nav.Link>
              </li>
              <li class="nav-item dropdown rounded mt-2">
              <Nav.Link  class="nav-link dropdown-toggle " href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <img src={support} alt='Employment Exchange' className='img-fluid' /></Nav.Link >
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <Dropdown.Item as={Link} to="/SupportCenter">Support Center</Dropdown.Item>
              <Dropdown.Item as={Link} to="/Feedback">Feedback</Dropdown.Item>
              <Dropdown.Item as={Link} to="/Askmeanything">Ask me anything</Dropdown.Item>
                </ul>
              </li>
              <li class="nav-item rounded">
              <ul class="notification-drop">
                <li class="item">
                <Nav.Link as={Link} to="/Messages"> <i class="bi bi-chat-left-fill"></i>
              <span class="btn__badge pulse-button ">4</span> </Nav.Link>
                </li>
        </ul>           
              </li>

              <li class="nav-item rounded">
              <ul class="notification-drop">
                <li class="item">
                <Nav.Link > <i class="bi bi-bell-fill"></i>
              <span class="btn__badge pulse-button ">4</span>  </Nav.Link>   
              <ul className='shadow'>
              <li>David Lee <br/>
                <span className='roboto-light'>Hey, I just read your message. How are you?</span>
                </li>
                <li>David Lee <br/>
                <span className='roboto-light'>Hey, I just read your message. How are you?</span>
                </li>
                <li>David Lee <br/>
                <span className='roboto-light'>Hey, I just read your message. How are you?</span>
                </li>
                <li>David Lee <br/>
                <span className='roboto-light'>Hey, I just read your message. How are you?</span>
                </li>
                <li>David Lee <br/>
                <span className='roboto-light'>Hey, I just read your message. How are you?</span>
                </li>
                <li>
                <span className='roboto-light text-center'> <Nav.Link as={Link} to="/Notifications">View All</Nav.Link></span>
                </li>
            </ul>
          </li>
        </ul>
              </li>
              <li class="nav-item dropdown  pt-2 ml-2" style={{borderLeft:'1px solid #D5D5D5'}}>
              <Nav.Link class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="bi bi-caret-down-fill"></i></Nav.Link>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                <Dropdown.Item><span className='fw-bold'>Employee Name</span></Dropdown.Item>
                <Dropdown.Item as={Link} to="/Myprofile">Profile</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/Changepassword">Change Password  </Dropdown.Item>
            <hr></hr>
                    <Dropdown.Item onClick={logoutUser}>Logout</Dropdown.Item>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>   
         
    </div>
  )
}

export default Header