import React from "react";
import Container from "react-bootstrap/Container";
import InputGroup from 'react-bootstrap/InputGroup';
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Logo from "../Assets/EmploymentExchange_Logo.svg";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { useState } from "react";
import Authuser from "../Authuser";
function Adminlogin() {
  const [values, setValues] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({ email: "", password: "", message: ""});
  const {http,setToken} = Authuser();
  const [submitted, setsubmitted] = useState(false);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState("fa-solid fa-eye-slash");
  const show = () => {
    type === "password" ? setType("text") : setType("password");
    icon === "fa-solid fa-eye"
      ? setIcon("fa-solid fa-eye-slash")
      : setIcon("fa-solid fa-eye");
  };
  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    // Validate email
    if (!values.email.trim()) {
        newErrors.email = 'Email is required';
        isValid = false;
    } else  if(!emailRegex.test(values.email)){
        newErrors.email = 'Email not valid';
        isValid = false;
    }else {
        newErrors.email = '';
    }

    // Validate password
    if (!values.password.trim()) {
        newErrors.password = 'Password is required';
        isValid = false;
    } else {
        newErrors.password = '';
    }

    setErrors(newErrors);
    return isValid;
  };
  function handleChange(e) {
    setValues({ ...values, [e.target.name]: e.target.value });
  }
  const handleSubmit = async (e) =>{
    e.preventDefault();
    let email = values.email;
    let password = values.password;
    const newErrors = { ...errors };
    
    if (validateForm()) {
      http.post('/employeeselfservice/login',{email:email,password:password}).then((response)=>{
        setToken(response.data.userdetail,response.data.token);
      }).catch(function (error){
        console.log(error)
        if(error?.response?.status==403){
          newErrors.email = error.response.data?.error?.email;
          newErrors.password = error.response.data?.error?.password;
          setErrors(newErrors);
        }
        if(error.response.status==401){
          newErrors.message = error.response.data?.error;
          setErrors(newErrors);
        }
      })
    } else {
      // Form is not valid, display error messages

    }
  }
  return (
    <Container fluid className="Regbg pt-4 pb-4">
      <Row className="min-vh-75">
        <Card className="rounded-0" style={{ width: "25rem", margin: "auto" }}>
          <Card.Body>
            <Row>
              <Col lg={12}>
                <img src={Logo} alt="Profile" className="img-fluid p-2 " />
                <h6 className="text-center fw-bold">
                  <i className="bi bi-person-fill"></i> Employee Login
                </h6>
                <Form className="mt-3" method="post" onSubmit={handleSubmit}>
                  <div class="mb-2 col-md-12">
                    <Form.Control type="email" name="email" className="rounded-0 p-3 shadow-sm" placeholder="Email" onChange={handleChange}/>
                    <span style={{display:'block', color: "#bc3f34" }} className="invalid-feedback">{errors.email}</span>  
                  </div>
                  <div class="mb-2 col-md-12">
                    <Form.Control type={type} name="password" className="rounded-0 p-3 shadow-sm" placeholder="Password" onChange={handleChange} />
                    <i onClick={show} className={icon} style={{float: "right",marginRight:'5px',marginTop:'-30px',position: "relative",zIndex:"2"}}></i>     
                    <span style={{display:'block', color: "#bc3f34" }}className="invalid-feedback">{errors.password}</span>    
                    <div style={{ display: "block" }} className="invalid-feedback small">{errors.message}</div>   
                  </div>                  
                  <div className="d-grid gap-2">
                    <Button variant="primary" type="submit" size="sm" style={{ backgroundColor: "#2F9DCC" }} className="rounded-0 fw-bold border-0 shadow" onClick={validateForm} disabled={submitted} 
                    >
                      {" "}
                      Login{" "}
                    </Button>
                  </div>
                </Form>
                <p className="text-center mt-2 p-0">
                  <Card.Link as={Link} to="/ForgotPassword">
                    Forgot Password?
                  </Card.Link>
                </p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
}

export default Adminlogin;
