import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
function Messages() {
  return (
    <Container fluid>
    <Container className='mt-3'>
        <Row>
        <div class="message-area">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="chat-area">
                           <div class="chatlist">
                            <div class="modal-dialog-scrollable">
                                <div class="modal-content">
                                    <div class="chat-header">
                                        <div class="msg-search">
                                        <Form.Control
              type="search"
              placeholder="Search by name"
              className="me-2  rounded-0"
              aria-label="Search"
            />
                                            <Card.Link class="add" href="#"><Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/add.svg" alt="add"/></Card.Link>
                                        </div>

                                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link active" id="Open-tab" data-bs-toggle="tab" data-bs-target="#Open" type="button" role="tab" aria-controls="Open" aria-selected="true">Open</button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link" id="Closed-tab" data-bs-toggle="tab" data-bs-target="#Closed" type="button" role="tab" aria-controls="Closed" aria-selected="false">Closed</button>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="modal-body">
                                     
                                        <div class="chat-lists">
                                            <div class="tab-content" id="myTabContent">
                                                <div class="tab-pane fade show active" id="Open" role="tabpanel" aria-labelledby="Open-tab">
                                                 
                                                    <div class="chat-list">
                                                    <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                                <span class="active"></span>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Mehedi Hasan</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                            </Card.Link>
                                                            <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Ryhan</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                            </Card.Link>
                                                            <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Malek Hasan</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                            </Card.Link>
                                                            <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Sadik Hasan</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                            </Card.Link>
                                                            <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Bulu </h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                            </Card.Link>
                                                        <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Maria SK</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                            </Card.Link>
                                                        <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Dipa Hasan</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                            </Card.Link>
                                                        <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Jhon Hasan</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                            </Card.Link>
                                                        <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Tumpa Moni</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                            </Card.Link>
                                                        <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Payel Akter</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                            </Card.Link>
                                                        <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Baby Akter</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                            </Card.Link>
                                                        <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Zuwel Rana</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                            </Card.Link>
                                                        <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Habib </h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                            </Card.Link>
                                                        <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Jalal Ahmed</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                            </Card.Link>
                                                            <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Hasan Ali</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                            </Card.Link>
                                                            <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Mehedi Hasan</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                            </Card.Link>


                                                    </div>
                                                  
                                                </div>
                                                <div class="tab-pane fade" id="Closed" role="tabpanel" aria-labelledby="Closed-tab">
                                         
                                                    <div class="chat-list">
                                                        <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                                <span class="active"></span>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Mehedi Hasan</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                            </Card.Link>
                                                        <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Ryhan</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                            </Card.Link>
                                                        <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Malek Hasan</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                            </Card.Link>
                                                        <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Sadik Hasan</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                            </Card.Link>
                                                        <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Bulu </h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                            </Card.Link>
                                                        <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Maria SK</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                        </Card.Link>
                                                        <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Dipa Hasan</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                        </Card.Link>
                                                        <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Jhon Hasan</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                        </Card.Link>
                                                        <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Tumpa Moni</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                        </Card.Link>
                                                        <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Payel Akter</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                        </Card.Link>
                                                        <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Baby Akter</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                        </Card.Link>
                                                        <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Zuwel Rana</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                        </Card.Link>
                                                        <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Habib </h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                        </Card.Link>
                                                        <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Jalal Ahmed</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                        </Card.Link>
                                                        <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Hasan Ali</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                        </Card.Link>
                                                        <Card.Link href="#" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>Mehedi Hasan</h3>
                                                                <p>front end developer</p>
                                                            </div>
                                                        </Card.Link>

                                                    </div>
                                                   
                                                </div>
                                            </div>

                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    


                    
                        <div class="chatbox">
                            <div class="modal-dialog-scrollable">
                                <div class="modal-content">
                                    <div class="msg-head">
                                        <div class="row">
                                            <div class="col-8">
                                                <div class="d-flex align-items-center">
                                                    <span class="chat-icon"><Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/arroleftt.svg" alt="image title"/></span>
                                                    <div class="flex-shrink-0">
                                                        <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                    </div>
                                                    <div class="flex-grow-1 ms-3">
                                                        <h3>Mehedi Hasan</h3>
                                                        <p>front end developer</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <ul class="moreoption">
                                                    <li class="navbar nav-item dropdown">
                                                    <Card.Link class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></Card.Link>
                                                        <ul class="dropdown-menu">
                                                            <li> <Card.Link class="dropdown-item" href="#">Action</Card.Link></li>
                                                            <li><Card.Link class="dropdown-item" href="#">Another action</Card.Link></li>
                                                            <li>
                                                               
                                                            </li>
                                                            <li><Card.Link class="dropdown-item" href="#">Something else here</Card.Link></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="modal-body">
                                        <div class="msg-body">
                                            <ul>
                                                <li class="sender">
                                                    <p> Hey, Are you there? </p>
                                                    <span class="time">10:06 am</span>
                                                </li>
                                                <li class="sender">
                                                    <p> Hey, Are you there? </p>
                                                    <span class="time">10:16 am</span>
                                                </li>
                                                <li class="repaly">
                                                    <p>yes!</p>
                                                    <span class="time">10:20 am</span>
                                                </li>
                                                <li class="sender">
                                                    <p> Hey, Are you there? </p>
                                                    <span class="time">10:26 am</span>
                                                </li>
                                                <li class="sender">
                                                    <p> Hey, Are you there? </p>
                                                    <span class="time">10:32 am</span>
                                                </li>
                                                <li class="repaly">
                                                    <p>How are you?</p>
                                                    <span class="time">10:35 am</span>
                                                </li>
                                                <li>
                                                    <div class="divider">
                                                        <h6>Today</h6>
                                                    </div>
                                                </li>

                                                <li class="repaly">
                                                    <p> yes, tell me</p>
                                                    <span class="time">10:36 am</span>
                                                </li>
                                                <li class="repaly">
                                                    <p>yes... on it</p>
                                                    <span class="time">junt now</span>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>


                                    <div class="send-box">
                                        <Row>
                                         <Col sm={12} md={12} lg={10}>
                                         <Form.Control type="text" class="form-control" aria-label="message…" placeholder="Write message…"/>
                                         </Col>

                                         <Col sm={12} md={12} lg={2}>
                                         <button type="button"><i class="fa fa-paper-plane" aria-hidden="true"></i> Send</button>
                                         </Col>   
                                        </Row>
                                       
                                        
                                           <div class="send-btns mt-2">
                                            <div class="attach">
                                                <div class="button-wrapper">
                                                    <span class="label">
                                                        <Image class="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/upload.svg" alt="image title"/> attached file 
                                                    </span>
                                                    <Form.Control type="file" name="upload" id="upload" class="upload-box" placeholder="Upload File" aria-label="Upload File"/>
                                                </div>

                                                

                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                 


                </div>
            </div>
        </div>
        </div>
 

        </Row>
</Container>
    </Container>
);
}
  
    export default Messages