import React,{ useState,useMemo,useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import ViewDownload from "../Components/ViewDownload";
import Authuser from '../Authuser';
function NewEmployeeDocumentation(){
    const { http, getUser } = Authuser();
    let userdetails = getUser();
    const [values,setValues]=useState({title:'',document:''});
    const [errors, setErrors] = useState({title: '',document: '',message:'' });
    const [message,setMessage]= useState();
    // Row Data: The data to be displayed.
    const [rowData, setRowData] = useState([]);
    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
        { headerName:"Title",field: "title"}, 
        { headerName:"",field: "essdocument",
            cellRenderer: ViewDownload,
            cellRendererParams: {
                folderName: 'empselfdocs' // Pass folder name or other data as custom params
            }
        },
    ]);    
    // ...
    const defaultColDef = useMemo(() => {
        return {
            flex: 1, 
            filter: true,
        }
    });
    function fetchItems(){
        http.get(`/employeeselfservice/newempdocuments/${userdetails.email}`)
          .then((response) => {
            //console.log(response.data);
            setRowData(response.data.documents);
          })
          .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
          });
    }
    useEffect(() => {
        fetchItems();
    }, []);
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Validate email
        if (!values.title.trim()) {
            newErrors.title = 'Title is required';
            isValid = false;
        } else {
            newErrors.title = '';
        }

        // Validate password
        if (!values.document.trim()) {
            newErrors.document = 'Document is required';
            isValid = false;
        } else {
            newErrors.password = '';
        }

        setErrors(newErrors);
        setMessage('');
        return isValid;
    };
    function handleChange(e){
        setMessage('');
        setValues({...values,[e.target.name]:e.target.value});
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        let title = values.title;
        let document = e.target.document.files[0];
        const newErrors = { ...errors };
        
        if (validateForm()) {
          http.post(`/employeeselfservice/addnewdocument/${userdetails.email}`,{title:title,document:document,userid:userdetails.id}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              console.log(response.data.message);
              setValues({
                title: '',
                document: '',
                message: '',
              });
              e.target.reset();
              fetchItems();
              setMessage('Document added successfully!');
              //fetchItems();
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
                newErrors.title = error.response.data?.error?.title;
                newErrors.document = error.response.data?.error?.document;
                setErrors(newErrors);
            }
            if(error.response.status==401){
                newErrors.message = error.response.data?.error;
                setErrors(newErrors);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
  
    return (
        <Container fluid>
             <Breadcrumb className='mt-2'>
                <Breadcrumb.Item as={Link} to="/Dashboard">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item active> New Employee Documentation</Breadcrumb.Item>               
            </Breadcrumb>
            <Row className='m-1'>
                <Col sm={4} className='d-flex'>
                    <h6>New Employee Documentation</h6>
                </Col>
                <Col sm={4} className='pb-2'>
                </Col>
                <Col sm={4} className="d-flex justify-content-end">              
                </Col>
                <div className="ag-theme-quartz m-auto" // applying the grid theme
                style={{ height: 550, width:500, }} // the grid will fill the size of the parent container
                >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}                                                          
                        defaultColDef={defaultColDef}                       
                    />
                    <p>Note : Upload and Submit the completed I9 / W4 / Direct Deposit Authorization form along with void  check in my documents.</p>
                </div>
           </Row>
        </Container>
    );
}

export default  NewEmployeeDocumentation