import React,{useState,useEffect} from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Authuser from '../Authuser';
function Changepassword() {
  const{http,getUser}=Authuser();
  let userdetails = getUser();
  const [password, setPassword] = useState("Abc.@678");
  const [values,setValues]=useState({oldpassword:'',password:'',confpassword:''});
  const [errors, setErrors] = useState({oldpassword:'',password:'',confpassword:'' });
  const [errorMessage, setErrorMessage] = useState("");
  const [message,setMessage]=useState();
  const validateForm = () => {
      let isValid = true;
      const newErrors = { ...errors };
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
       // Validate email
       if (!values.oldpassword.trim()) {
        newErrors.oldpassword = 'Old Password is required';
        isValid = false;
    } else {
        newErrors.oldpassword = '';
    }
      // Validate email
      if (!values.password.trim()) {
          newErrors.password = 'Password is required';
          isValid = false;
      } else {
          newErrors.password = '';
      }

      // Validate password
      if (!values.confpassword.trim()) {
          newErrors.confpassword = 'Confirm Password is required';
          isValid = false;
      } else {
          newErrors.confpassword = '';
      }

      setErrors(newErrors);
      return isValid;
  };
  const handlePassword = (event) => {
      let new_pass = event.target.value;
      let new_name = event.target.name;
      
      setPassword(new_pass);
      setValues({...values,[new_name]:event.target.value})
      const newErrors = { ...errors };
      // regular expressions to validate password
      var lowerCase = /[a-z]/g;
      var upperCase = /[A-Z]/g;
      var numbers = /[0-9]/g;
      if (!new_pass.match(lowerCase)) {
          newErrors.new_name = "Password should contains lowercase letters!";
      } else if (!new_pass.match(upperCase)) {
          newErrors.new_name = "Password should contain uppercase letters!";
      } else if (!new_pass.match(numbers)) {
          newErrors.new_name = "Password should contains numbers also!";
      } else if (new_pass.length < 8) {
          newErrors.new_name = "Password length should be more than 8.";
      } else {
          newErrors.new_name='';
      }
  }
  const handleSubmit = async (e) =>{
      e.preventDefault();
      //validation(values);
      let oldpassword = values.oldpassword;
      let password = values.password;
      let confpassword = values.confpassword;
      let email = userdetails.email;
      const newErrors = { ...errors };
      
      if (validateForm()) {
        http.post(`/employeeselfservice/changepassword/${userdetails.email}`,{oldpassword:oldpassword,newpassword:password,retypepassword:confpassword,email:email})
        .then((response)=>{
            //setToken(response.data.userdetail,response.data.token);
            //console.log(response.status);
            console.log(response.data);
            e.target.reset();
            setMessage('Password changed successfully!');
            
        }).catch(function (error) {
          // handle error
          console.log(error);
          //console.log(error.response.data.error);
          if(error?.response?.status==403){
            newErrors.oldpassword = error.response.data?.error;
            setErrors(newErrors);
          }
          if(error?.response?.status==401){
            newErrors.oldpassword = error.response.data?.error?.oldpassword;
            newErrors.confpassword = error.response.data?.error?.retypepassword;
            newErrors.password = error.response.data?.error?.newpassword;
            setErrors(newErrors);
          }
          if(error.response.status==401){
            newErrors.message = error.response.data?.error;
            setErrors(newErrors);
          }
          
          //setsubmitted(false)
        })
      } else {
        // Form is not valid, display error messages

      }
  }
  return (
    <Container fluid>
      <Container className='mt-2'>
        <Row className='min-vh-75'>
          <Card className='rounded-0 ' style={{ width: '25rem', margin:'auto'}}>
            <Card.Body>
              <p className='fw-bold'>Change Password</p>
              <hr></hr>
              <Row className='p-2 '>
                <Form onSubmit={handleSubmit} method="post">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Current Password *</Form.Label>
                      <Form.Control type="password" name="oldpassword" onChange={handlePassword} placeholder="Enter Current Password" className='rounded-0' />
                      <span style={{display:'block', color: "#bc3f34" }} className="invalid-feedback">{errors.oldpassword}</span> 
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                      <Form.Label>New Password *</Form.Label>
                      <Form.Control type="password" name="password" onChange={handlePassword}  placeholder="Enter New Password" className='rounded-0' />
                      <span style={{display:'block', color: "#bc3f34" }} className="invalid-feedback">{errors.password}</span> 
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                      <Form.Label>Re-confirm Password *</Form.Label>
                      <Form.Control type="password" name="confpassword" onChange={handlePassword}  placeholder="Enter Re-confirm Password" className='rounded-0' />
                      <span style={{display:'block', color: "#bc3f34" }} className="invalid-feedback">{errors.confpassword}</span> 
                      <div style={{display:'block', color: "#1cc88a" }} className="alert-success small">{message}</div> 
                    </Form.Group>
                    <Col className='text-center'>
                    <Button variant="secondary" className='rounded-0'>Cancel</Button>
                    <Button type="submit" variant="primary" onClick={validateForm} className='rounded-0 ml-2'>Update</Button>
                    </Col>
                </Form>
              </Row>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </Container>
  );
}
  
export default Changepassword